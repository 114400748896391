import { DEBOUNCE_TIME_DEFAULT } from 'constants/debounce';
import {
  SortOption,
  useJobRoleFindManyPaginatedQuery,
} from 'generated/graphql';
import { debounce } from 'lodash';
import { FC, useMemo, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { EmptyTableContent } from 'views/JobRoles/EmptyTableContent';
import {
  COLUMNS,
  DEFAULT_TABLE_PAGE_SIZE,
  getSortField,
  parseRow,
  QueryParams,
  TOrder,
} from 'views/JobRoles/JobRoles.helpers';
import styles from 'views/JobRoles/JobRoles.module.scss';
import {
  GridRowSelectionModel,
  GridSortModel,
  ItemsCount,
  Table,
} from '@spotted-zebra-uk/ui';
import { Search } from '@spotted-zebra-uk/ui-components';

/**
 *
 * State management of this component is handled through
 * the query parameters, rather than the useState hook.
 * This avoids having two sources of truth for variables
 * which may misalign in certain scenarios.
 * i.e. if the user presses the browser back button
 *
 */
const JobRoles: FC = () => {
  const getOrder = (queryParams: URLSearchParams): TOrder => {
    return {
      direction:
        queryParams.get(QueryParams.ORDER_DIRECTION) === 'asc'
          ? SortOption.Asc
          : SortOption.Desc,
      field: getSortField(
        queryParams.get(QueryParams.ORDER_FIELD) ?? 'createdAt'
      ),
    };
  };
  const getSearchValue = (queryParams: URLSearchParams): string => {
    return queryParams.get(QueryParams.SEARCH) ?? '';
  };

  const getPagination = (
    queryParams: URLSearchParams
  ): { page: number; pageSize: number } => {
    return {
      page: Number(queryParams.get(QueryParams.PAGE) ?? 1) - 1,
      pageSize:
        Number(queryParams.get(QueryParams.PAGE_SIZE)) ||
        DEFAULT_TABLE_PAGE_SIZE,
    };
  };

  const setPagination = (
    { page, pageSize }: { page: number; pageSize: number } | undefined = {
      pageSize: DEFAULT_TABLE_PAGE_SIZE,
      page: 0,
    }
  ) => {
    if (!page) {
      queryParams.delete(QueryParams.PAGE);
    } else {
      queryParams.set(QueryParams.PAGE, String(page + 1));
    }
    if (pageSize === DEFAULT_TABLE_PAGE_SIZE) {
      queryParams.delete(QueryParams.PAGE_SIZE);
    } else {
      queryParams.set(QueryParams.PAGE_SIZE, String(pageSize));
    }
    setQueryParams(queryParams);
  };

  const setSearchValue = (searchValue: string) => {
    setPagination({ page: 0, pageSize });
    if (searchValue) {
      queryParams.set(QueryParams.SEARCH, searchValue);
    } else {
      queryParams.delete(QueryParams.SEARCH);
    }
    setQueryParams(queryParams);
  };

  const debouncedSetSearchValue = debounce(
    setSearchValue,
    DEBOUNCE_TIME_DEFAULT
  );

  const setOrdering = (orderFields: GridSortModel) => {
    if (!orderFields.length) {
      queryParams.delete(QueryParams.ORDER_FIELD);
      queryParams.delete(QueryParams.ORDER_DIRECTION);
      setQueryParams(queryParams);
      return;
    }
    const orderField = orderFields[0];
    queryParams.set(QueryParams.ORDER_FIELD, orderField.field);
    queryParams.set(QueryParams.ORDER_DIRECTION, orderField.sort ?? 'desc');
    setQueryParams(queryParams);
  };

  const handleClearSelection = () => {
    setSelectedRows([]);
  };

  // ---
  const location = useLocation();
  const [queryParams, setQueryParams] = useSearchParams(
    new URLSearchParams(location.search.slice(1))
  );
  const searchValue = getSearchValue(queryParams);
  const { page, pageSize } = getPagination(queryParams);
  const order = getOrder(queryParams);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const rowCountRef = useRef(0);

  const { data, loading: loadingTable } = useJobRoleFindManyPaginatedQuery({
    variables: {
      pagination: {
        // new Table component seems to
        // expect 0 based indexing for pages
        page: page + 1,
        size: pageSize,
      },
      filters: {
        search: searchValue,
      },
      order: order,
    },
    fetchPolicy: 'cache-and-network',
  });

  const pageInfo = data?.JobRoleFindManyPaginated?.page;

  const rows = useMemo(
    () => data?.JobRoleFindManyPaginated.data?.map(parseRow),
    [data?.JobRoleFindManyPaginated.data]
  );
  const rowCount = useMemo(() => {
    if (pageInfo?.itemsTotal !== undefined) {
      rowCountRef.current = pageInfo.itemsTotal;
    }
    return rowCountRef.current;
  }, [pageInfo?.itemsTotal]);

  return (
    <div className={styles.container} data-testid="job-roles__table-container">
      <h1 className={styles.heading}>Job roles</h1>
      <Table
        rows={rows}
        columns={COLUMNS}
        autosizeOnMount
        autosizeOptions={{
          expand: true,
          columns: ['projectsCount'],
        }}
        loading={loadingTable}
        rowCount={rowCount}
        paginationMode="server"
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={setPagination}
        keepNonExistentRowsSelected
        emptyTableContent={<EmptyTableContent />}
        disableColumnFilter
        hasToolbar
        slotProps={{
          toolbar: {
            leftMainAction: <ItemsCount count={rowCount} text="Items" />,
            ...(selectedRows.length && {
              left: (
                <ItemsCount
                  count={selectedRows.length}
                  text="Selected"
                  dismissible
                  onClick={handleClearSelection}
                />
              ),
            }),
            right: (
              <Search
                onInputChange={debouncedSetSearchValue}
                value={{ label: searchValue, value: searchValue }}
                className={styles.toolbarSearch}
                isDisabled={false}
              />
            ),
          },
        }}
        onSortModelChange={setOrdering}
        sx={{
          minHeight: '600px',
        }}
      />
    </div>
  );
};

export default JobRoles;
