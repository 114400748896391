import Icon from 'components/atoms/Icon';
import { FieldArray, useFormikContext } from 'formik';
import { SoftSkillRelationSelectFormField } from 'views/StaticContent/SoftSkills/drawers/children/SoftSkillRelationSelectFormField';
import { TEditSoftSkillFormValues } from 'views/StaticContent/SoftSkills/drawers/SoftSkillDrawer.helpers';
import { Button } from '@spotted-zebra-uk/ui-components';
import styles from './SoftSkillRelations.module.scss';

type TSoftSkillRelationsFormProps = {
  isBroadSoftSkill: boolean;
  isEditMode: boolean;
};

export const SoftSkillRelationsForm = ({
  isBroadSoftSkill,
  isEditMode,
}: TSoftSkillRelationsFormProps) => {
  const { values } = useFormikContext<TEditSoftSkillFormValues>();
  return (
    <div>
      <FieldArray
        name={isBroadSoftSkill ? 'focusedSoftSkills' : 'broadSoftSkills'}
        render={fieldArrayHelpers => (
          <>
            <div className={styles.softSkillRelationsContainer}>
              {(isBroadSoftSkill
                ? values.focusedSoftSkills
                : values.broadSoftSkills
              ).map((_, index) => (
                <div key={index} className={styles.softSkillRelationContainer}>
                  <SoftSkillRelationSelectFormField
                    isEditMode={isEditMode}
                    id={
                      isBroadSoftSkill
                        ? `focusedSoftSkills.${index}`
                        : `broadSoftSkills.${index}`
                    }
                    isBroadSoftSkill={isBroadSoftSkill}
                    label={isBroadSoftSkill ? 'Focused skill' : 'Broad skill'}
                    placeholder="Name"
                    maxMenuHeight={240}
                    index={index}
                  />
                  {isEditMode && (
                    <Button
                      onClick={() => fieldArrayHelpers.remove(index)}
                      size="medium"
                      variant="negative"
                      type="button"
                      leftIcon={
                        <Icon icon="delete_outline" className={styles.icon} />
                      }
                    />
                  )}
                </div>
              ))}
            </div>
            {isEditMode && (
              <Button
                onClick={() => {
                  fieldArrayHelpers.push({ label: '', value: '' });
                }}
                size="medium"
                variant="primary"
                type="button"
                leftIcon={<Icon icon="add" className={styles.icon} />}
              >
                Add relation
              </Button>
            )}
          </>
        )}
      ></FieldArray>
    </div>
  );
};
