import { DEBOUNCE_TIME_DEFAULT } from 'constants/debounce';
import { useCompanyAtsSelect } from 'hooks/ats/useCompanyAtsSelect/useCompanyAtsSelect';
import { debounce } from 'lodash';
import { useState } from 'react';
import {
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TSelectOption,
} from '@spotted-zebra-uk/ui-components';
import { useStackOneJobsFindManyPaginatedQuery } from '../../generated/graphql';
import styles from './AtsLinks.module.scss';
import { LinkedTab } from './LinkedTab/LinkedTab';
import { UnlinkedTab } from './UnlinkedTab/UnlinkedTab';

export function AtsLinks() {
  const [selectedCompany, setSelectedCompany] = useState<TSelectOption>();
  const [index, setIndex] = useState<number>(0);

  const selectedCompanyId = Number(selectedCompany?.value);

  const selectedCompanyName = selectedCompany?.label || '';

  const {
    selectOptions,
    loading,
    searchValue,
    setSearchValue,
    hasMoreItems,
    loadMore,
  } = useCompanyAtsSelect();

  const { data: unlinkedJobs, refetch: refetchUnlinked } =
    useStackOneJobsFindManyPaginatedQuery({
      variables: {
        companyId: selectedCompanyId,
        take: 1,
        skip: 0,
        hasProjectLinked: false,
      },
      skip: !Boolean(selectedCompanyId),
    });

  const { data: linkedJobs, refetch: refetchLinked } =
    useStackOneJobsFindManyPaginatedQuery({
      variables: {
        companyId: selectedCompanyId,
        take: 1,
        skip: 0,
        hasProjectLinked: true,
      },
      skip: !Boolean(selectedCompanyId),
    });

  function handleInputChange(value: string) {
    if (!value && searchValue !== '') {
      setSearchValue('');
      return;
    }

    if (value) {
      setSearchValue(value);
      return;
    }
  }

  const debouncedInputChange = debounce(
    handleInputChange,
    DEBOUNCE_TIME_DEFAULT
  );

  const unlinkedJobsCount =
    unlinkedJobs?.stackOneJobsFindManyPaginated.pageInfo?.itemsTotal;

  const linkedJobsCount =
    linkedJobs?.stackOneJobsFindManyPaginated.pageInfo?.itemsTotal;

  function handleRefresh() {
    refetchUnlinked();
    refetchLinked();
  }

  return (
    <section className={styles.container} data-testid="ats-links-page">
      <h1 className={styles.heading}>ATS links</h1>

      <Select
        id="ats-links-company-selector"
        placeholder="Company"
        value={selectedCompany}
        options={selectOptions}
        onChange={value => {
          setSelectedCompany(value);
        }}
        onClearBtnClick={() => {
          setSelectedCompany(undefined);
        }}
        onLoadMore={loadMore}
        onInputChange={debouncedInputChange}
        loadMore={hasMoreItems}
        loadMoreButtonText="Load more"
        isLoading={loading}
        className={styles.companyDropdown}
      />

      <Tabs className={styles.tabs} onSelectTab={index => setIndex(index)}>
        <TabList>
          <Tab counter={unlinkedJobsCount}>Unlinked requisitions</Tab>
          <Tab counter={linkedJobsCount}>Linked requisitions</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {index === 0 ? (
              <UnlinkedTab
                selectedCompanyId={selectedCompanyId}
                selectedCompanyName={selectedCompanyName}
                key={`unlinked-tab-view-${selectedCompanyId}`}
                onRefetch={handleRefresh}
              />
            ) : null}
          </TabPanel>
          <TabPanel>
            {index === 1 ? (
              <LinkedTab
                selectedCompanyId={selectedCompanyId}
                selectedCompanyName={selectedCompanyName}
                key={`linked-tab-view-${selectedCompanyId}`}
                onRefetch={handleRefresh}
              />
            ) : null}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </section>
  );
}
