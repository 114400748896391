import styles from './AtsLinks.module.scss';

export function EmptyTableContent() {
  return (
    <div className={styles.emptyTableContent}>
      Select a company to begin.
      <br />
      <br />
      This will allow you to start viewing and sorting the requsitions tied to
      the company’s ATS.
    </div>
  );
}
