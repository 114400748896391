import { FC } from 'react';
import { Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  SoftSkillFragment,
  useSoftSkillFindManyQuery,
} from '../../../../generated/graphql';
import {
  TFormFieldValue,
  TMultiSelectFormFieldValue,
} from '../../../../interfaces/forms/form';
import MultiselectFormField from '../MultiselectFormField';

export interface ISoftSkillFiltersMultiselectFormField {
  id: string;
  name: string;
  value: TMultiSelectFormFieldValue;
  label: string;
  onChange: (value: TFormFieldValue, name: string) => void;
  onBlur?: (value: string, name: string) => void;
  hasError?: boolean;
  errorText?: string;
}

const SoftSkillFiltersMultiselectFormField: FC<ISoftSkillFiltersMultiselectFormField> =
  props => {
    const { handleMsgType } = useNotification();

    const softSkillQueryResponse = useSoftSkillFindManyQuery({
      onError: error => {
        handleMsgType({ type: TNotification.error, message: error?.message });
      },
    });

    if (softSkillQueryResponse.error) {
      return <Typography>Error</Typography>;
    }

    if (softSkillQueryResponse.loading) {
      return <Typography>Loading soft skill filters..</Typography>;
    }

    if (softSkillQueryResponse.data) {
      const softSkills = (
        softSkillQueryResponse.data.SoftSkillFindMany as SoftSkillFragment[]
      ).filter(softSkill => !softSkill.deletedAt);
      return (
        <MultiselectFormField
          {...props}
          inputProps={{
            'data-role': 'soft-skill-filter-select',
          }}
          options={softSkills.map(softSkill => ({
            label: softSkill.name,
            value: softSkill.id,
          }))}
        />
      );
    }

    return null;
  };

export default SoftSkillFiltersMultiselectFormField;
