import {
  JobRoleFindManyPaginatedOrderField,
  JobRolePaginatedFragment,
  SortOption,
} from 'generated/graphql';
import { formatDate, formatISO } from 'helpers/date';
import styles from 'views/JobRoles/JobRoles.module.scss';
import { GridCellParams } from '@spotted-zebra-uk/ui';

export const DEFAULT_TABLE_PAGE_SIZE = 20;

export type TOrder = {
  field: JobRoleFindManyPaginatedOrderField;
  direction: SortOption;
};

export enum QueryParams {
  ORDER_DIRECTION = 'orderDirection',
  ORDER_FIELD = 'orderField',
  SEARCH = 'search',
  PAGE = 'page',
  PAGE_SIZE = 'pageSize',
}

export const getSortField = (
  field: string
): JobRoleFindManyPaginatedOrderField => {
  switch (field) {
    case 'jobRoleName':
      return JobRoleFindManyPaginatedOrderField.Name;
    case 'companyName':
      return JobRoleFindManyPaginatedOrderField.CompanyName;
    case 'updatedAt':
      return JobRoleFindManyPaginatedOrderField.UpdatedAt;
    case 'createdAt':
    default:
      return JobRoleFindManyPaginatedOrderField.CreatedAt;
  }
};

export const COLUMNS = [
  {
    field: 'jobRoleName',
    headerName: 'Name',
    flex: 1,
    width: 300,
  },
  {
    field: 'companyName',
    headerName: 'Company name',
    flex: 0.8,
    width: 200,
  },
  { field: 'createdAt', headerName: 'Created at', width: 150, flex: 0.3 },
  { field: 'updatedAt', headerName: 'Updated at', width: 150, flex: 0.3 },
  {
    field: 'projectsCount',
    headerName: 'Project count',
    width: 100,
    flex: 0.3,
    sortable: false,
    renderCell: ({ value }: GridCellParams) => {
      if (!value) {
        return <span className={styles.disabledCell}>{String(value)}</span>;
      }
      return <span>{String(value)}</span>;
    },
  },
];

export const parseRow = (jobRole: JobRolePaginatedFragment['data'][number]) => {
  return {
    id: jobRole.subId,
    jobRoleName: jobRole.name,
    companyName: jobRole.company.name,
    createdAt: formatDate(formatISO(jobRole.createdAt), 'dd MMM yyyy'),
    updatedAt: formatDate(formatISO(jobRole.updatedAt), 'dd MMM yyyy'),
    projectsCount: jobRole.projectsCount,
  };
};
