import { initialLanguageOption } from 'constants/languages';
import { Formik, useField } from 'formik';
import {
  ProjectModuleType,
  SupportedLocale as SupportedLocaleType,
} from 'generated/graphql';
import { FC, useMemo } from 'react';
import * as yup from 'yup';
import {
  FormikCheckboxFormField,
  FormikMultiselectFormField,
  FormikSelectFormField,
  FormikTextInputField,
  InlineNotification,
  ModalButtons,
  NotificationType,
  TMultiselectOption,
  TSelectOption,
  useLocales,
} from '@spotted-zebra-uk/ui-components';

interface IInviteNewCandidateForm {
  availableAssignees: TMultiselectOption[];
  projectLanguages: SupportedLocaleType[] | undefined;
  projectId: number;
  type: ProjectModuleType;
  onCancel: () => void;
  onSubmit: (
    givenName: string,
    familyName: string,
    markedAsInternalCandidate: boolean,
    email: string,
    managerEmail: string,
    assignees: TMultiselectOption[],
    locale: SupportedLocaleType
  ) => void;
}

interface IInviteNewCandidateFormValues {
  givenName: string;
  familyName: string;
  email: string;
  markedAsInternalCandidate: boolean;
  managerFullName: string;
  managerEmail: string;
  assignees: TMultiselectOption[];
  language: TSelectOption;
}

const initialValues: IInviteNewCandidateFormValues = {
  givenName: '',
  familyName: '',
  email: '',
  markedAsInternalCandidate: false,
  assignees: [],
  language: initialLanguageOption,
  managerFullName: '',
  managerEmail: '',
};

const validationSchema = yup.object().shape({
  givenName: yup
    .string()
    .required('This is a required field.')
    .max(50, 'Must be under 50 characters.'),
  familyName: yup
    .string()
    .required('This is a required field.')
    .max(50, 'Must be under 50 characters.'),
  email: yup.string().required().email(),
  language: yup
    .object()
    .shape({ label: yup.string(), value: yup.string().required() }),
});

const InviteNewCandidateForm: FC<IInviteNewCandidateForm> = props => {
  const { getSupportedLocaleMetadata, SupportedLocale } = useLocales();

  const handleFromSubmit = (values: IInviteNewCandidateFormValues) => {
    props.onSubmit(
      values.givenName,
      values.familyName,
      values.markedAsInternalCandidate,
      values.email,
      values.managerEmail,
      values.assignees,
      (values.language.value as SupportedLocaleType) ||
        initialLanguageOption.value
    );
  };

  const languageSelectFieldOptions = useMemo(
    () =>
      props.projectLanguages?.length
        ? props.projectLanguages.map(locale => ({
            label: getSupportedLocaleMetadata(SupportedLocale[locale]).name
              .english,
            value: locale,
          }))
        : [initialLanguageOption],
    [props.projectLanguages, getSupportedLocaleMetadata, SupportedLocale]
  );

  return (
    <Formik<IInviteNewCandidateFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFromSubmit}
    >
      {({ handleSubmit, values }) => (
        <div className="invite-new-candidate-form">
          <div className="invite-new-candidate-form__fields">
            <FormikTextInputField
              id="givenName"
              name="givenName"
              label="Given Name"
              placeholder="Given Name"
              data-testid="invite-candidate__given-name"
              useFormikField={useField}
            />
            <FormikTextInputField
              id="familyName"
              name="familyName"
              label="Family Name"
              placeholder="Family Name"
              data-testid="invite-candidate__family-name"
              useFormikField={useField}
            />
            <FormikTextInputField
              id="email"
              name="email"
              label="Email"
              placeholder="Email"
              useFormikField={useField}
            />
            <FormikCheckboxFormField
              id="markedAsInternalCandidate"
              name="markedAsInternalCandidate"
              label="Mark as internal candidate"
              useFormikField={useField}
            />
            {values.markedAsInternalCandidate && (
              <InlineNotification
                title="Make candidate log in with SSO."
                notificationType={NotificationType.WARNING}
                className="invite-new-candidate-form__sso-notification"
              >
                <p>
                  Only use this for candidates from your organization who are
                  logging in with company email address.
                </p>
              </InlineNotification>
            )}
            {props.type === ProjectModuleType.Hiring ? (
              <FormikMultiselectFormField
                id="assignees"
                name="assignees"
                label="Assignees"
                placeholder="Assignees"
                options={props.availableAssignees}
                useFormikField={useField}
              />
            ) : (
              <>
                <FormikTextInputField
                  id="managerFullName"
                  name="managerFullName"
                  label="Manager's Full Name"
                  placeholder="Manager's Full Name"
                  useFormikField={useField}
                />
                <FormikTextInputField
                  id="managerEmail"
                  name="managerEmail"
                  label="Manager's Email"
                  placeholder="Manager's Email"
                  useFormikField={useField}
                />
              </>
            )}
            <FormikSelectFormField
              id="language"
              name="language"
              label="Language"
              placeholder="Language"
              options={languageSelectFieldOptions}
              isDisabled={!props.projectLanguages?.length}
              data-testid="direct-invite-candidate__language-select-form"
              useFormikField={useField}
              hasClearIndicator={false}
            />
          </div>
          <ModalButtons
            onConfirmButtonClick={() => handleSubmit()}
            onCancelButtonClick={props.onCancel}
            confirmButtonChildren="Invite"
            confirmButtonProps={{
              size: 'medium',
            }}
            cancelButtonProps={{
              size: 'medium',
            }}
          />
        </div>
      )}
    </Formik>
  );
};

export default InviteNewCandidateForm;
