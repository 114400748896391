import {
  GetProjectByIdDocument,
  InterviewStageFragment,
  useProjectAddInterviewStageMutation,
  useProjectJobRoleFindManyQuery,
  useStageFindManyQuery,
} from 'generated/graphql';
import { useMemo, useState } from 'react';
import InterviewStageOverview from 'views/Project/components/Stages/InterviewStageOverview/InterviewStageOverview';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './Stage.module.scss';
import StageActions from './StageActions';
import StageForm from './StageForm/StageForm';
import { initializeValues, initialValues } from './StageForm/StageForm.helper';
import StageHeader from './StageHeader/StageHeader';
import StageOverview from './StageOverview/StageOverview';

type StageProps = {
  projectId: number;
  projectSubId: string;
  reportsAccess: boolean;
  interviewStages: InterviewStageFragment[];
};

export type StageType = 'interview' | 'assessment';

const Stage = ({
  projectId,
  reportsAccess,
  interviewStages,
  projectSubId,
}: StageProps) => {
  const { handleMsgType } = useNotification();
  const [isEditing, setIsEditing] = useState<StageType | null>(null);

  const { data: stageData, loading: loadingStage } = useStageFindManyQuery({
    variables: { projectId },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const { data: projectsData } = useProjectJobRoleFindManyQuery({
    variables: { id: projectId },
  });

  const [projectAddInterviewStage] = useProjectAddInterviewStageMutation({
    variables: {
      subId: projectSubId,
    },
    refetchQueries: [GetProjectByIdDocument],
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        title: 'Successfully created interview stage',
      });
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        title: 'Failed to create interview stage',
        message: error?.message,
      });
    },
  });

  const projectJobRoles = useMemo(
    () => projectsData?.ProjectJobRoleFindMany || [],
    [projectsData?.ProjectJobRoleFindMany]
  );

  const isMultiMatch = projectJobRoles.length > 1;

  const stage = useMemo(() => {
    return stageData?.Stages?.[0];
  }, [stageData]);

  const prefillValues = useMemo(
    () => (stage ? initializeValues(stage) : initialValues),
    [stage]
  );

  // If no stage exists, use company's default reports access type. Otherwise use the type set on existing stage
  const stageReportAccess = stage
    ? stage.managerReportEmailLinks
    : reportsAccess;

  const handleEditStage = (type?: StageType) => {
    // Temporarily, clicking the button in the dropdown will instantly create the interview stage. In the future a form will open to add settings
    if (type === 'interview') {
      projectAddInterviewStage();
      return;
    }
    setIsEditing('assessment');
  };

  const handleCancelForm = () => {
    setIsEditing(null);
  };

  if (loadingStage) {
    return <Loader variant="bubbles" />;
  }

  return (
    <div className={styles.container}>
      <StageHeader
        title="Stages"
        primaryButton={{
          label: 'Insert stage',
          onClick: handleEditStage,
          type: 'dropdown',
          disabled: Boolean(isEditing) || !!(interviewStages.length && stage), // Will be disabled when one of each stage is added on the project
          disabledDropdown: stage
            ? 'assessment'
            : !!interviewStages.length
            ? 'interview'
            : undefined, // Will be disabled if that type of stage is added and the other is not added yet
        }}
      />
      <div className={styles.stagesContent}>
        {!!interviewStages.length && (
          <>
            <StageHeader
              title="Interview stage"
              deleteInterviewStage={{
                label: 'Remove',
                subId: interviewStages[0].subId, // Only one interview stage at a time for now
              }}
            />
            <InterviewStageOverview interviewStages={interviewStages} />
          </>
        )}
        {isEditing === 'assessment' ? (
          <StageForm
            stageId={stage?.id}
            projectId={projectId}
            reportsAccess={stageReportAccess}
            isMultiMatch={isMultiMatch}
            onCancel={handleCancelForm}
            isLoading={false}
            onSubmit={handleCancelForm}
            initialValues={prefillValues}
          />
        ) : stage ? (
          <>
            <StageHeader
              title="Assessment stage"
              primaryButton={{
                label: 'Edit',
                onClick: () => handleEditStage('assessment'),
              }}
              deleteButton={{ projectId, stageId: stage.id }}
            />
            <StageOverview stage={stage} projectId={projectId} />
            <StageActions stageId={stage.id} projectId={projectId} />
          </>
        ) : null}
      </div>
    </div>
  );
};
export default Stage;
