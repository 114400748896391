import { ReactComponent as IconEye } from 'icons/eye_filled.svg';
import { ReactComponent as IconNewTab } from 'icons/open_in_new.svg';
import { Link } from 'react-router-dom';
import styles from './PreviewLink.module.scss';

type PreviewLinkProps = {
  link: string;
  text: string;
};

export function PreviewLink({ link, text }: PreviewLinkProps) {
  return (
    <Link to={link} target="_blank" className={styles.previewGroupLink}>
      <IconEye />
      <div>{text}</div>
      <IconNewTab />
    </Link>
  );
}
