import { projectsRoutes } from 'constants/navigation';
import { StackOneJobsFindManyPaginatedQuery } from 'generated/graphql';
import { formatDate, formatISO } from 'helpers/date';
import { Link } from 'react-router-dom';
import { GridCellParams, Stack } from '@spotted-zebra-uk/ui';
import { Tag, TagColor } from '@spotted-zebra-uk/ui-components';
import styles from '../AtsLinks.module.scss';

export function parseRowData(
  data: StackOneJobsFindManyPaginatedQuery | undefined
) {
  if (!data) return [];

  return data.stackOneJobsFindManyPaginated.data?.map(dataPoint => ({
    id: dataPoint.job.id,
    linked: formatDate(
      formatISO(dataPoint.jobProject?.updatedAt),
      'dd MMM yyy'
    ),
    created: formatDate(formatISO(dataPoint.job.createdAt), 'dd MMM yyyy'),
    name: dataPoint.job.title,
    code: dataPoint.job.code,
    departments: '',
    // TODO: fix after BE fix
    // departments: dataPoint.job?.departments
    //   ?.map(department => department.name)
    //   ?.join(', '),
    status: dataPoint.job.jobStatus,
    project: '',
    projectId: dataPoint.jobProject?.projectId,
  }));
}

export const COLUMNS = [
  { field: 'linked', headerName: 'Linked' },
  { field: 'created', headerName: 'Created' },
  { field: 'name', headerName: 'Name', flex: 0.45, maxWidth: 300 },
  { field: 'code', headerName: 'Code', flex: 0.1, maxWidth: 100 },
  {
    field: 'departments',
    headerName: 'Departments',
    flex: 0.45,
    maxWidth: 300,
    renderCell: (params: GridCellParams) => {
      const { value } = params;

      if (value) return String(value);

      return <span className={styles.disabledCell}>-</span>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (params: GridCellParams) => {
      const { value } = params;

      const valueStr = String(value).toLocaleLowerCase();

      if (!valueStr) {
        return <span className={styles.disabledCell}>Unmapped</span>;
      }

      const isStatusPositive = checkIfStatusIsPositive(valueStr);

      const statusColor = isStatusPositive ? TagColor.GREEN : TagColor.YELLOW;

      return (
        <Stack height="100%" justifyContent="center">
          <Tag tagColor={statusColor} isDismissible={false} isPresentational>
            {String(value)}
          </Tag>
        </Stack>
      );
    },
  },
  { field: 'project', headerName: 'Project' },
  {
    field: 'projectId',
    headerName: 'Project ID',
    renderCell: (params: GridCellParams) => {
      const { value } = params;
      const projectId = Number(value);

      return (
        <Stack height="100%" justifyContent="center">
          <Link
            to={projectsRoutes.editProjectView.url(projectId)}
            target="_blank"
            className={styles.link}
          >
            {projectId}
          </Link>
        </Stack>
      );
    },
  },
];

function checkIfStatusIsPositive(status: string) {
  if (['published', 'internal', 'open'].includes(status)) {
    return true;
  }

  return false;
}
