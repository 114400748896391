import { FormikContextType, useField, useFormikContext } from 'formik';
import {
  softSkillCategoryOptions,
  softSkillGranularityOptions,
  TAddSoftSkillFormValues,
} from 'views/StaticContent/SoftSkills/drawers/SoftSkillDrawer.helpers';
import {
  Button,
  FormikSelectFormField,
  FormikTextInputField,
  InlineNotification,
  NotificationType,
  TNotification,
  TSelectOption,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './AddSoftSkillDrawerContents.module.scss';

type TAddSoftSkillDrawerContentsArgs = {
  companySelectOptions: TSelectOption<number | null>[];
  setIsAddMode: (b: boolean) => void;
};

export const AddSoftSkillDrawerContents = ({
  companySelectOptions,
  setIsAddMode,
}: TAddSoftSkillDrawerContentsArgs) => {
  const onSave = (formProps: FormikContextType<TAddSoftSkillFormValues>) => {
    formProps.validateForm(formProps.values);
    if (formProps.isValid && !formProps.isValidating) {
      formProps.submitForm();
    } else {
      handleMsgType({
        type: TNotification.error,
        message: `Invalid form values!\n${JSON.stringify(formProps.errors)}`,
      });
    }
  };
  const onCancel = (formProps: FormikContextType<TAddSoftSkillFormValues>) => {
    formProps.resetForm();
    setIsAddMode(false);
  };

  const formContext = useFormikContext<TAddSoftSkillFormValues>();
  const { handleMsgType } = useNotification();

  return (
    <div>
      <div className={styles.buttonContainer}>
        <>
          <Button
            className={styles.button}
            onClick={() => onSave(formContext)}
            variant="primary"
            size="large"
          >
            Save
          </Button>
          <Button
            className={styles.button}
            onClick={() => onCancel(formContext)}
            variant="negative"
            size="large"
          >
            Cancel
          </Button>
        </>
      </div>
      <InlineNotification
        notificationType={NotificationType.INFORMATION}
        className={styles.inlineNotification}
      >
        Adding a skill to the system is permanent, but you will be able to
        archive or edit it later. Additional details, such as traits and
        relations to other skills can be added after the skill is created.
      </InlineNotification>
      <div>
        <div className={styles.editableFieldsContainer}>
          <FormikTextInputField
            id="name"
            label="Name"
            data-role="soft-skill-name-input"
            data-testid="soft-skill-name-input"
            useFormikField={useField}
            placeholder={'Name'}
          />
          <FormikTextInputField
            id="description"
            label="Description"
            data-role="soft-skill-description-input"
            data-testid="soft-skill-description-input"
            useFormikField={useField}
            placeholder={'Description'}
          />
          <div className={styles.fieldWithWarning}>
            <FormikSelectFormField
              id="owner"
              label="Ownership"
              options={companySelectOptions}
              data-role="category-select"
              placeholder="Owner"
              maxMenuHeight={240}
              selectProps={{
                'data-testid': 'ownership-level-select',
              }}
              useFormikField={useField}
              hasClearIndicator={false}
            />
            <InlineNotification
              notificationType={NotificationType.WARNING}
              className={styles.inlineWarning}
            >
              Ownership cannot be changed after creation!
            </InlineNotification>
          </div>
          <FormikTextInputField
            id="taxonomyVersion"
            label="Taxonomy version"
            data-role="soft-skill-taxonomy-version-input"
            data-testid="soft-skill-taxonomy-version-input"
            useFormikField={useField}
            placeholder={'Version'}
            type="number"
          />
          <div className={styles.fieldWithWarning}>
            <FormikSelectFormField
              id="granularity"
              label="Granularity"
              options={softSkillGranularityOptions}
              data-role="category-select"
              placeholder="Granularity"
              maxMenuHeight={240}
              selectProps={{
                'data-testid': 'granularity-level-select',
              }}
              useFormikField={useField}
              hasClearIndicator={false}
            />
            <InlineNotification
              notificationType={NotificationType.WARNING}
              className={styles.inlineWarning}
            >
              Granularity cannot be changed after creation!
            </InlineNotification>
          </div>
          <FormikSelectFormField
            id="category"
            label="Category"
            options={softSkillCategoryOptions}
            data-role="category-select"
            placeholder="Category"
            maxMenuHeight={240}
            selectProps={{
              'data-testid': 'category-level-select',
            }}
            useFormikField={useField}
            hasClearIndicator={false}
          />
        </div>
      </div>
    </div>
  );
};
