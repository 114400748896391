import './styles.scss';
import {
  Project,
  ProjectCollaborator,
  ProjectCollaboratorsFindManyPaginatedQuery,
  ProjectModuleType,
  ProjectsDocument,
  ProjectsQuery,
  ProjectsQueryVariables,
  useEditProjectMutation,
  useGetProjectByIdQuery,
  useProjectCollaboratorsCreateManyMutation,
  useProjectCollaboratorsDeleteManyMutation,
  useProjectCollaboratorsFindManyPaginatedQuery,
  useProjectCollaboratorsUpdateRoleMutation,
} from 'generated/graphql';
import { IProjectPath } from 'interfaces/routes';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { ProjectAuthenticationSettings } from './components/ProjectAuthenticationSettings/ProjectAuthenticationSettings';
import ProjectJobRolesOverview from './components/ProjectJobRolesOverview/ProjectJobRolesOverview';
import ProjectLanguageForm, {
  IProjectLanguageFormSubmitValues,
} from './components/ProjectLanguageForm';
import ProjectOverviewForm, {
  IProjectOverviewFormSubmitValues,
} from './components/ProjectOverviewForm';
import ProjectReportsForm from './components/ProjectReportsForm';
import Stage from './components/Stages/Stage';
import ProjectBar from './ProjectBar';
import {
  ProjectLanguageInfo,
  ProjectOverviewInfo,
  ProjectReportsInfo,
} from './ProjectInfo';

const ProjectEdit: FC = () => {
  const { handleMsgType } = useNotification();
  const { projectId, projectRequestId } = useParams() as IProjectPath;
  const parsedProjectId = Number(projectId);
  const parsedProjectRequestId = Number(projectRequestId);
  const [editProjectOverviewFormActive, setEditProjectOverviewFormActive] =
    useState(false);
  const [editProjectLanguageFormActive, setEditProjectLanguageFormActive] =
    useState(false);

  const getProjectByIdResponse = useGetProjectByIdQuery({
    variables: { id: parsedProjectId },
    fetchPolicy: 'network-only',
    onError: error =>
      handleMsgType({
        type: TNotification.error,
        title: error?.message,
      }),
  });

  const [collaboratorsPage, setCollaboratorsPage] = useState<number>(1);
  const [collaboratorsPageSize] = useState<number>(500);
  const [collaborators, setCollaborators] = useState<
    Array<ProjectCollaborator>
  >([]);
  const [collaboratorsReady, setCollaboratorsReady] = useState<boolean>(false);

  const { refetch: refetchCollaborators, loading: collaboratorsLoading } =
    useProjectCollaboratorsFindManyPaginatedQuery({
      skip: !parsedProjectId,
      variables: {
        args: {
          projectId: parsedProjectId,
          membersPreviewCount: 12,
        },
        paginate: {
          page: collaboratorsPage,
          size: collaboratorsPageSize,
        },
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: ProjectCollaboratorsFindManyPaginatedQuery) => {
        const pageInfo = data.collaborators?.pageInfo;
        if (!pageInfo || !data.collaborators?.data) return;
        const { currentPage, pageTotal } = pageInfo;
        setCollaborators([
          ...collaborators,
          ...(data.collaborators?.data as Array<ProjectCollaborator>),
        ]);
        if (currentPage < pageTotal) {
          setCollaboratorsPage(collaboratorsPage + 1);
        } else {
          setCollaboratorsReady(true);
        }
      },
    });

  const [createProjectCollaborators] =
    useProjectCollaboratorsCreateManyMutation({});
  const [updateRole] = useProjectCollaboratorsUpdateRoleMutation({});
  const [deleteProjectCollaborators] =
    useProjectCollaboratorsDeleteManyMutation({});

  const [editProjectMutation, { loading }] = useEditProjectMutation({
    update(cache, { data }) {
      const project = data?.project as Project;
      if (project) {
        const cachedData = cache.readQuery<
          ProjectsQuery,
          ProjectsQueryVariables
        >({
          query: ProjectsDocument,
        });
        if (cachedData) {
          cache.writeQuery({
            query: ProjectsDocument,
            data: {
              projects: cachedData.projects.map(p =>
                p.id === project.id ? project : p
              ),
            },
          });
        }
      }
    },
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        title: 'Project successfully updated',
      });
    },
    onError: error =>
      handleMsgType({
        type: TNotification.error,
        title: error?.message,
      }),
  });

  const handleEditProjectOverview = async ({
    name,
    productSolution,
    companyId,
    requireSheetsExport,
    collaborators: updatedCollaborators,
    removedCollaborators,
  }: IProjectOverviewFormSubmitValues) => {
    const toSync = updatedCollaborators.map(collaborator => {
      const found = collaborators.find(
        pc =>
          (collaborator.userId && pc.userId === collaborator.userId) ||
          (collaborator.groupId && pc.groupId === collaborator.groupId)
      );
      return { ...collaborator, id: found?.id || null };
    });
    const toRemove = removedCollaborators.map(collaborator => {
      const found = collaborators.find(
        pc =>
          (collaborator.userId && pc.userId === collaborator.userId) ||
          (collaborator.groupId && pc.groupId === collaborator.groupId)
      );
      return { ...collaborator, id: found?.id || null };
    });
    const toUpdate = toSync.filter(collaborator => collaborator.id);
    const toCreate = toSync.filter(collaborator => !collaborator.id);

    await Promise.all([
      editProjectMutation({
        variables: {
          name,
          ...(productSolution && { productSolution: productSolution }),
          companyId: Number(companyId),
          id: parsedProjectId,
          requireSheetsExport,
        },
      }),
      toCreate.length
        ? createProjectCollaborators({
            variables: {
              collaborators: toCreate.map(collaborator => ({
                actorRole: collaborator.role,
                userId: collaborator.userId,
                groupId: collaborator.groupId,
                projectId: parsedProjectId,
              })),
            },
          })
        : null,
      Promise.all(
        toUpdate.map(collaborator =>
          updateRole({
            variables: {
              collaboratorId: collaborator.id as number,
              actorRole: collaborator.role,
            },
          })
        )
      ),
      removedCollaborators.length
        ? deleteProjectCollaborators({
            variables: {
              collaborators: toRemove
                .map(collaborator => collaborator.id)
                .filter(x => !!x) as Array<number>,
            },
          })
        : null,
    ]);
    setCollaborators([]);
    setCollaboratorsPage(1);
    setCollaboratorsReady(false);
    refetchCollaborators();
    setEditProjectOverviewFormActive(false);
  };

  const handleEditProjectLocales = ({
    locales,
  }: IProjectLanguageFormSubmitValues) => {
    const project = getProjectByIdResponse?.data?.project;
    if (!project) {
      handleMsgType({
        type: TNotification.error,
        title: 'Could not save project languages at this time.',
      });
      return;
    }
    if (!locales.length) {
      handleMsgType({
        type: TNotification.error,
        title: 'Select at least one language for this project',
      });
      return;
    }
    editProjectMutation({
      variables: {
        name: project.name,
        id: parsedProjectId,
        companyId: Number(project.company?.id),
        locales,
      },
      onCompleted: () => setEditProjectLanguageFormActive(false),
    });
  };

  if (getProjectByIdResponse.loading || collaboratorsLoading) {
    return (
      <div className="project-loader-wrapper">
        <Loader variant="bubbles" />
      </div>
    );
  }

  const project = getProjectByIdResponse.data?.project;

  return project && collaboratorsReady ? (
    <div className="project">
      <div className="project-edit">
        <ProjectBar
          project={project}
          projectRequestId={parsedProjectRequestId}
        />
        <div className="project-create__title">{project.name}</div>
        {editProjectOverviewFormActive ? (
          <ProjectOverviewForm
            project={project}
            collaborators={collaborators || []}
            onCancel={() => setEditProjectOverviewFormActive(false)}
            onProjectSave={handleEditProjectOverview}
            saveLoading={loading}
          />
        ) : (
          <ProjectOverviewInfo
            project={project}
            collaborators={collaborators}
            onEdit={() => setEditProjectOverviewFormActive(true)}
          />
        )}
        <div className="project-edit__advanced-settings">
          <Accordion>
            <AccordionItem
              id="advanced-settings"
              title="Advanced Settings"
              ariaLabel="Advanced Settings"
            >
              {project.moduleType === ProjectModuleType.Hiring ? (
                <>
                  <ProjectReportsInfo />
                  <ProjectReportsForm project={project} />
                </>
              ) : null}
              {editProjectLanguageFormActive ? (
                <ProjectLanguageForm
                  project={project}
                  onCancel={() => setEditProjectLanguageFormActive(false)}
                  onProjectSave={handleEditProjectLocales}
                  saveLoading={loading}
                />
              ) : (
                <ProjectLanguageInfo
                  project={project}
                  onEdit={() => setEditProjectLanguageFormActive(true)}
                />
              )}

              {project.company && (
                <ProjectAuthenticationSettings
                  projectId={parsedProjectId}
                  companyId={project.company.id}
                />
              )}
            </AccordionItem>
          </Accordion>
        </div>

        {project.company && (
          <ProjectJobRolesOverview
            projectId={project.id}
            companyId={project.company.id}
          />
        )}
        {project.company && (
          <Stage
            projectId={project.id}
            projectSubId={project.subId}
            reportsAccess={project.company.managerReportEmailLinks}
            interviewStages={project.interviewStages}
          />
        )}
      </div>
    </div>
  ) : null;
};

export default ProjectEdit;
