import { Formik } from 'formik';
import {
  SoftSkillFragment,
  useSoftSkillQuery,
  useSoftSkillUpdateMutation,
} from 'generated/graphql';
import { useMemo, useState } from 'react';
import { EditSoftSkillDrawerContents } from 'views/StaticContent/SoftSkills/drawers/EditSoftSkillDrawerContents';
import {
  editSoftSkillValidationSchema,
  getEditSoftSkillInitialValues,
  TEditSoftSkillFormValues,
} from 'views/StaticContent/SoftSkills/drawers/SoftSkillDrawer.helpers';
import {
  Drawer,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

type TEditSoftSkillDrawerArgs = {
  isOpen: boolean;
  onClose: () => void;
  softSkillSubId: string | null;
};

export const EditSoftSkillDrawer = ({
  isOpen,
  softSkillSubId,
  onClose,
}: TEditSoftSkillDrawerArgs) => {
  const onCloseDrawer = () => {
    setIsEditMode(false);
    onClose();
  };

  const onSubmit = async (values: TEditSoftSkillFormValues) => {
    const { errors } = await softSkillUpdateMutation({
      variables: {
        subId: softSkillSubId ?? '',
        args: {
          name: values.name,
          description: values.description,
          category: values.category.value,
          companyId: values.owner.value,
          childSubIds: values.focusedSoftSkills.map(({ value }) => value),
          parentSubIds: values.broadSoftSkills.map(({ value }) => value),
          taxonomyVersion: Number(values.taxonomyVersion),
          softSkillTraits: values.softSkillTraits.map(sst => ({
            orientation: sst.orientation.value,
            traitId: sst.trait.value,
            weight: Number(sst.weight),
          })),
        },
      },
    });

    if (!errors) {
      setIsEditMode(false);
    }
  };

  const { handleMsgType } = useNotification();
  const [isEditMode, setIsEditMode] = useState(false);

  const [softSkillUpdateMutation] = useSoftSkillUpdateMutation({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
        dismissable: true,
        time: 1500,
      });
    },
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        message: `Soft skill updated!`,
        dismissable: true,
        time: 1500,
      });
    },
  });

  const { data, loading } = useSoftSkillQuery({
    variables: {
      subId: softSkillSubId ?? '',
    },
    skip: !isOpen,
  });
  const softSkill = data?.SoftSkill as SoftSkillFragment | undefined;

  const initialValues: TEditSoftSkillFormValues = useMemo(() => {
    return getEditSoftSkillInitialValues(softSkill);
  }, [softSkill]);

  return (
    <Drawer
      open={isOpen}
      loading={loading}
      title={'Edit Soft Skill'}
      onClose={onCloseDrawer}
    >
      {isOpen && (
        <div className="edit-soft-skill__drawer">
          <Formik<TEditSoftSkillFormValues>
            initialValues={initialValues}
            validationSchema={editSoftSkillValidationSchema}
            onSubmit={onSubmit}
          >
            <EditSoftSkillDrawerContents
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              softSkill={softSkill}
            />
          </Formik>
        </div>
      )}
    </Drawer>
  );
};
