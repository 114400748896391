import {
  GetProjectByIdDocument,
  useInterviewStageDeleteMutation,
} from 'generated/graphql';
import { FC, useState } from 'react';
import {
  Button,
  IconType,
  Modal,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './DeleteStage.module.scss';

interface IDeleteInterviewStage {
  subId: string;
}

const DeleteInterviewStage: FC<IDeleteInterviewStage> = props => {
  const { handleMsgType } = useNotification();
  const [modalOpen, setModalOpen] = useState(false);

  const [removeInterviewStage] = useInterviewStageDeleteMutation({
    variables: {
      subId: props.subId,
    },
    refetchQueries: [GetProjectByIdDocument],
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        title: 'Successfully removed interview stage',
      });
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        title: 'Failed to remove interview stage',
        message: error?.message,
      });
    },
  });

  const handleDelete = () => {
    removeInterviewStage();
  };

  return (
    <>
      <Button
        onClick={() => setModalOpen(true)}
        size="medium"
        variant="secondary"
        data-testid="stage-header__delete-stage-button"
      >
        Remove
      </Button>
      <Modal
        header="Are you sure?"
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        headerIconType={IconType.WARNING}
        buttonsProps={{
          confirmButtonChildren: 'Delete',
          onConfirmButtonClick: handleDelete,
          confirmButtonProps: {
            className: styles.modalBtn,
          },
        }}
      >
        <p className={styles.modalContentRow}>
          You are about to remove the <span>interview stage</span>. This action
          cannot be undone.
        </p>
        <p className={styles.modalContentRow}>
          Interview links for candidates will be removed, but they will remain
          in the project.
        </p>
      </Modal>
    </>
  );
};

export default DeleteInterviewStage;
