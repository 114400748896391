import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useUrlState = (
  fieldName: string
): [string | null, (value?: string | null) => void] => {
  const navigate = useNavigate();
  const location = useLocation();

  const value = new URLSearchParams(location.search).get(fieldName);

  const setUrlQueryState = useCallback(
    (value?: string | null) => {
      const searchParams = new URLSearchParams(location.search);
      if (value) {
        searchParams.set(fieldName, value);
      } else {
        searchParams.delete(fieldName);
      }

      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [fieldName, navigate, location.pathname, location.search]
  );

  return [value, setUrlQueryState];
};

export { useUrlState };
