import { useField, useFormikContext } from 'formik';
import {
  SoftSkillGranularity,
  useSoftSkillFindManyPaginatedQuery,
} from 'generated/graphql';
import { debounce } from 'lodash';
import uniqBy from 'lodash/uniqBy';
import { FC, useMemo, useState } from 'react';
import { TEditSoftSkillFormValues } from 'views/StaticContent/SoftSkills/drawers/SoftSkillDrawer.helpers';
import {
  IFormikSelectFormField,
  SelectFormField,
  TNotification,
  TSelectOption,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

interface ISoftSkillRelationSelectFormField
  extends Omit<IFormikSelectFormField, 'options'> {
  isEditMode: boolean;
  index: number;
  isBroadSoftSkill: boolean;
}

type PaginationStateType = Record<
  string,
  { currentPage?: number; totalPages?: number }
>;

const PAGE_SIZE = 20;
const SEARCH_DEBOUNCE_TIME = 500;

export const SoftSkillRelationSelectFormField: FC<ISoftSkillRelationSelectFormField> =
  ({ id, isBroadSoftSkill, isEditMode, index, ...rest }) => {
    const { handleMsgType } = useNotification();

    const [isLoading, setIsLoading] = useState(false);
    const { values, setFieldValue } =
      useFormikContext<TEditSoftSkillFormValues>();
    const [softSkills, setSoftSkills] = useState<
      { label: string; value: string }[]
    >(isBroadSoftSkill ? values.focusedSoftSkills : values.broadSoftSkills);
    const [pagination, setPagination] = useState<PaginationStateType>({
      '': {
        currentPage: 1,
        totalPages: undefined,
      },
    });

    const [searchValue, setSearchValue] = useState('');
    const [field, meta] = useField<TSelectOption>(id);

    const handleChange = (newValues: TSelectOption) => {
      if (newValues.value === field.value.value) return;

      const selectedRelation = softSkills.find(
        relationOption => relationOption.value === newValues.value
      );

      if (!selectedRelation) return;
      setFieldValue(
        isBroadSoftSkill
          ? `focusedSoftSkills.${index}`
          : `broadSoftSkills.${index}`,
        selectedRelation
      );
    };

    const hasError = Boolean(meta.error && meta.touched);
    const error = meta.error as unknown as
      | { label: string; value: string }
      | undefined;

    useSoftSkillFindManyPaginatedQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        filters: {
          companyId: values.owner.value,
          name: searchValue,
          granularity: isBroadSoftSkill
            ? SoftSkillGranularity.Focused
            : SoftSkillGranularity.Broad,
        },
        pagination: {
          page: pagination[searchValue]?.currentPage
            ? pagination[searchValue].currentPage
            : 1,
          size: PAGE_SIZE,
        },
      },
      onCompleted: data => {
        const loadedSkills = (data?.SoftSkillFindManyPaginated.data || []).map(
          ({ subId, name }) => ({ value: subId, label: name })
        );

        const newSoftSkills = uniqBy([...loadedSkills, ...softSkills], 'value');

        const pageInfo = data?.SoftSkillFindManyPaginated.pageInfo;

        setPagination(prev => ({
          ...prev,
          [searchValue]: {
            currentPage: pageInfo?.currentPage,
            totalPages: pageInfo?.pageTotal,
          },
        }));
        setSoftSkills(newSoftSkills);
        setIsLoading(false);
      },
      onError: error => {
        handleMsgType({ type: TNotification.error, message: error?.message });
        setIsLoading(false);
      },
    });

    const searchSoftSkills = async (inputValue: string) => {
      if (inputValue === searchValue || !inputValue) return;
      setIsLoading(true);
      setSearchValue(inputValue);
    };

    const debouncedSearchSoftSkills = debounce(
      searchSoftSkills,
      SEARCH_DEBOUNCE_TIME
    );

    const handleLoadMore = async () => {
      setIsLoading(true);
      const currentPage = pagination[searchValue]?.currentPage;
      const newPage = currentPage ? currentPage + 1 : 1;
      setPagination(prev => ({
        ...prev,
        [searchValue]: {
          currentPage: newPage,
          totalPages: pagination[searchValue]?.totalPages,
        },
      }));
    };

    const options = useMemo(() => {
      if (!softSkills || isLoading) return [];

      // Parse soft skills to options.
      const parsedOptions = softSkills.map(({ label, value }) => ({
        value,
        label,
        labelDescription: value,
      }));

      // Remove skills that are already selected in other rows from the options.
      const otherSelectedSoftSkillIds = (
        isBroadSoftSkill ? values.focusedSoftSkills : values.broadSoftSkills
      )
        .map(otherRelation => otherRelation.value)
        .filter(
          otherRelation => otherRelation && otherRelation !== field.value.value
        );

      const filteredOptions = parsedOptions.filter(option => {
        return !otherSelectedSoftSkillIds.includes(option.value);
      });

      // Sort options alphabetically.
      return filteredOptions.sort((a, b) =>
        a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
      );
    }, [isBroadSoftSkill, softSkills, isLoading, field.value.value, values]);

    const value = useMemo(
      () => options.find(option => option.value === field.value.value),
      [options, field.value.value]
    );
    const currentPage = pagination[searchValue]?.currentPage;
    const totalPages = pagination[searchValue]?.totalPages;
    const loadMore = currentPage && totalPages && currentPage < totalPages;

    return (
      <SelectFormField
        {...rest}
        id={id}
        value={value}
        onChange={handleChange}
        onInputChange={debouncedSearchSoftSkills}
        onLoadMore={handleLoadMore}
        loadMore={loadMore}
        options={options}
        hasError={hasError}
        bottomText={hasError ? error?.value : ''}
        hasClearIndicator={false}
        loadMoreButtonText="Load more results"
        isLoading={isLoading}
        isDisabled={!isEditMode}
        menuPortalTarget={null}
      />
    );
  };
