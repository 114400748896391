import FormFields from 'components/feature/jobRole/JobRoleFormFields/JobRoleFormFields';
import Decimal from 'decimal.js';
import { Formik } from 'formik';
import {
  CalculationMethod,
  ContentRoleLevel,
  GradingMethod,
  ProjectModuleType,
  SoftSkillFragment,
  SoftSkillType,
} from 'generated/graphql';
import { uniqBy } from 'lodash';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { FC, useMemo, useState } from 'react';
import * as yup from 'yup';
import {
  calculateSoftSkillWeightsSum,
  getEmptyFormSoftSkillValues,
} from './JobRoleForm.helpers';
import {
  IJobRoleFormSubmitValues,
  TJobRoleFormPredefinedValues,
  TJobRoleFormSoftSkillValues,
  TJobRoleFormValues,
} from './JobRoleForm.types';

const validationSchema = yup.object().shape({
  jobRoleName: yup
    .string()
    .required('Name is a required field.')
    .max(100, 'Name is too long.'),
  company: yup.object().shape({
    label: yup.string(),
    value: yup.string().required('Company is a required field.'),
  }),
  softSkills: yup
    .array()
    .ensure()
    .required('')
    .test(
      'softSkillsWeight',
      'Sum of soft skills weights must be 100.',
      (value, { parent }) => {
        if (!parent.customWeights) return true;
        if (!value) return false;
        return calculateSoftSkillWeightsSum(value) === 100;
      }
    ),
  family: yup.object().shape({
    value: yup.string().required('Job family is a required field.'),
    label: yup.string(),
  }),
});

type TJobRoleFormProps = {
  moduleType?: ProjectModuleType;
  predefinedValues?: TJobRoleFormPredefinedValues;
  useCustomWeightsVisible?: boolean;
  disableRoleLevelField?: boolean;
  predefinedSoftSkills?: SoftSkillFragment[];
  onSubmit: (softSkillValues: IJobRoleFormSubmitValues) => void;
  onCancel: () => void;
};

const JobRoleForm: FC<TJobRoleFormProps> = props => {
  const [traitsModalData, setTraitsModalData] = useState<number | undefined>();

  const handleOpenTraitsModal = (traitsModalSoftSkillIndex: number) => {
    setTraitsModalData(traitsModalSoftSkillIndex);
  };

  const handleCloseTraitsModal = () => {
    setTraitsModalData(undefined);
  };

  const parseSoftSkillValuesForSubmit = (
    softSkillValues: TJobRoleFormSoftSkillValues,
    customWeights: boolean
  ) => {
    const parsedTraitsValues = softSkillValues.traitsValues.map(
      traitValues => ({
        id: traitValues.id,
        traitId: traitValues.traitId,
        name: traitValues.name,
        weight: new Decimal(traitValues.weight).div(100).toNumber(),
        orientation: Number(traitValues.orientation.value),
      })
    );

    return {
      weight:
        customWeights && softSkillValues.weight
          ? new Decimal(softSkillValues.weight).div(100).toNumber()
          : null,
      softSkillId: Number(softSkillValues.softSkillId.value),
      type: softSkillValues.type.value as SoftSkillType,
      name: softSkillValues.name as string,
      traitsValues: parsedTraitsValues,
      includeInCalibration: softSkillValues.includeInCalibration,
    };
  };

  const handleSubmit = (values: TJobRoleFormValues) => {
    const filteredSoftSkillValues = values.softSkills.filter(
      softSkillValues =>
        softSkillValues.softSkillId &&
        softSkillValues.type &&
        softSkillValues.name
    );

    const submitSoftSkillsValues = filteredSoftSkillValues.map(
      softSkillValues =>
        parseSoftSkillValuesForSubmit(softSkillValues, values.customWeights)
    );

    const submitTechnicalSkillsValues = uniqBy(
      values.technicalSkills.filter(skill => skill.value !== '0'),
      'value'
    );

    props.onSubmit({
      softSkills: submitSoftSkillsValues,
      technicalSkills: submitTechnicalSkillsValues,
      roleLevel: values.roleLevel.value as ContentRoleLevel,
      calculationMethod: values.calculationMethod.value || '',
      gradingMethod: values.gradingMethod.value || '',
      jobRoleName: values.jobRoleName,
      additionalInformation: values.additionalInformation,
      company: values.company,
      family: values.family,
    });
  };

  const initialValues: TJobRoleFormValues = useMemo(() => {
    const defaultValues = {
      roleLevel: {
        value: ContentRoleLevel.IndividualContributor,
        label: 'Individual contributor',
      },
      company: { value: '' },
      jobRoleName: '',
      additionalInformation: '',
      calculationMethod:
        props.moduleType === ProjectModuleType.TalentReview
          ? { value: CalculationMethod.TrCustomCalculator }
          : {
              value:
                CalculationMethod.ZWeightedAverageWithSmarterAdjustmentAndLimit,
            },
      gradingMethod: { value: GradingMethod.Percentile },
      softSkills: [getEmptyFormSoftSkillValues()],
      technicalSkills: [],
      customWeights: false,
      family: { value: '', label: '' },
    };

    const parsedPredefinedValues = omitBy(props.predefinedValues, isUndefined);

    return {
      ...defaultValues,
      ...parsedPredefinedValues,
    };
  }, [props.moduleType, props.predefinedValues]);

  return (
    <Formik<TJobRoleFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <FormFields
        traitsModalSoftSkillIndex={traitsModalData}
        useCustomWeightsVisible={props.useCustomWeightsVisible}
        disableRoleLevelField={props.disableRoleLevelField}
        onOpenTraitsModal={handleOpenTraitsModal}
        onCloseTraitsModal={handleCloseTraitsModal}
        onCancel={props.onCancel}
        predefinedSoftSkills={props.predefinedSoftSkills}
      />
    </Formik>
  );
};

export default JobRoleForm;
