import { SoftSkillType } from 'generated/graphql';
import { sumOfArrayField } from 'helpers/calculations';
import { TJobRoleFormSoftSkillValues } from './JobRoleForm.types';

const SOFT_SKILLS_CORE = {
  value: SoftSkillType.Core,
  label: 'CORE',
};

export const getEmptyFormSoftSkillValues = (): TJobRoleFormSoftSkillValues => ({
  softSkillId: { label: '', value: '' },
  name: '',
  type: SOFT_SKILLS_CORE,
  weight: '',
  traitsValues: [],
  includeInCalibration: false,
});

export const calculateSoftSkillWeightsSum = (
  value: TJobRoleFormSoftSkillValues[]
) => {
  const parsedValues = value.map(value => ({
    ...value,
    weight: Number(value.weight),
  }));
  return sumOfArrayField(parsedValues, 'weight');
};

export enum ProfileSkillsTabsValues {
  SOFT_SKILLS = 'SOFT_SKILLS',
  TECHNICAL_SKILLS = 'TECHNICAL_SKILLS',
}
