import {
  endOfDay,
  format,
  formatISO as formatISOFns,
  isBefore as isBeforeFns,
  parse,
  parseISO,
  set,
  startOfDay,
  sub,
} from 'date-fns';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';

const formatDate = (dateInput: string, dateOutputFormat: string) =>
  format(parseISO(dateInput), dateOutputFormat);

const formatISO = (dateInput: string | number) =>
  formatISOFns(new Date(dateInput));

const isBefore = (date: string, dateToCompate: string) =>
  isBeforeFns(new Date(date), new Date(dateToCompate));

const getUtcBoundaryOfDay = (date: Date, boundary: 'start' | 'end'): string => {
  let localStartOfDay: Date;
  if (boundary === 'start') {
    localStartOfDay = startOfDay(date);
  } else {
    localStartOfDay = endOfDay(date);
  }
  const timezoneOffsetMinutes = localStartOfDay.getTimezoneOffset();
  const utcTimeMilliseconds =
    localStartOfDay.getTime() - timezoneOffsetMinutes * 60 * 1000;
  // Create a new Date object with the calculated UTC time
  const utcDate = new Date(utcTimeMilliseconds);
  return utcDate.toISOString();
};

export const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

export function getTodaysDateBeginningOfTheDay() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

export function getMaximumYearDate() {
  const today = new Date();
  today.setFullYear(today.getFullYear() + 100);
  return today;
}

export function createUTCDate() {
  return getUTC(new Date());
}

export function getUTC(date: Date) {
  return fromZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
}

export const getUTCEndDate = (dateInput: string) => {
  let parsedDate = parse(dateInput, 'EEE MMM dd yyyy', new Date());
  let utcDate = set(parsedDate, { hours: 23, minutes: 59, seconds: 59 });
  return formatInTimeZone(utcDate, 'UTC', "yyyy-MM-dd'T'23:59:59");
};

export { sub, formatDate, formatISO, isBefore, getUtcBoundaryOfDay };
