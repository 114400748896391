import { IdentityPermissionRole } from 'generated/graphql';

export enum AccessLevels {
  FULL = 'Full',
  STANDARD = 'Standard',
}

export const getAccessLevel = (
  accessLevels: IdentityPermissionRole[]
): AccessLevels => {
  if (!accessLevels) return AccessLevels.STANDARD;
  const fullAccessLevels = accessLevels?.filter(accessLvl => {
    return (
      accessLvl === IdentityPermissionRole.CompanyAdmin ||
      accessLvl === IdentityPermissionRole.Admin ||
      accessLvl === IdentityPermissionRole.SzSuperAdmin
    );
  });

  return fullAccessLevels?.length ? AccessLevels.FULL : AccessLevels.STANDARD;
};
