import { ContentRoleLevel, JobRoleFamily } from 'generated/graphql';
import { capitalize } from 'lodash';

export const specialJobFamilyLabels: Record<string, string> = {
  [JobRoleFamily.AgricultureHorticultureOutdoors]:
    'Agriculture, horticulture & the outdoors',
  [JobRoleFamily.BusinessManagementOperations]:
    'Business management and operations',
  [JobRoleFamily.ClericalAdministrative]: 'Clerical and administrative',
  [JobRoleFamily.CommunitySocialServices]: 'Community and social services',
  [JobRoleFamily.ConstructionExtractionArchitecture]:
    'Construction, extraction, and architecture',
  [JobRoleFamily.CustomerClientSupport]: 'Customer and client support',
  [JobRoleFamily.DesignMediaWriting]: 'Design, media, and writing',
  [JobRoleFamily.EducationTraining]: 'Education and training',
  [JobRoleFamily.HospitalityFoodTourism]: 'Hospitality, food, and tourism',
  [JobRoleFamily.InformationTechnologyComputerScience]:
    'Information technology and computer science',
  [JobRoleFamily.LawCompliancePublicSafety]:
    'Law, compliance, and public safety',
  [JobRoleFamily.MaintenanceRepairInstallation]:
    'Maintenance, repair, and installation',
  [JobRoleFamily.ManufacturingProduction]: 'Manufacturing and production',
  [JobRoleFamily.MarketingPublicRelations]: 'Marketing and public relations',
  [JobRoleFamily.SocialAnalysisPlanning]: 'Social analysis and planning',
  [JobRoleFamily.ScienceResearch]: 'Science and research',
};

export const formatJobMetadata = (value?: string) => {
  return value ? capitalize(value.toLocaleLowerCase().replace(/_/g, ' ')) : '';
};
const mapEnumToLabelValue = (enumObject: Record<string, string>) => {
  return Object.values(enumObject).map(value => ({
    label: specialJobFamilyLabels[value] || formatJobMetadata(value),
    value: value,
  }));
};

export const roleLevelOptions = mapEnumToLabelValue(ContentRoleLevel);

export const jobFamilyOptions = mapEnumToLabelValue(JobRoleFamily);
