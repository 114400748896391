import Icon from 'components/atoms/Icon';
import { DEBOUNCE_TIME_DEFAULT } from 'constants/debounce';
import {
  SoftSkillFragment,
  useCompaniesQuery,
  useSoftSkillFindManyPaginatedQuery,
} from 'generated/graphql';
import { debounce } from 'lodash';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AddSoftSkillDrawer } from 'views/StaticContent/SoftSkills/drawers/AddSoftSkillDrawer';
import { EditSoftSkillDrawer } from 'views/StaticContent/SoftSkills/drawers/EditSoftSkillDrawer';
import { EmptyTableContent } from 'views/StaticContent/SoftSkills/EmptyTableContent';
import {
  COLUMNS,
  DEFAULT_TABLE_PAGE_SIZE,
  getCompanyDropdownOptions,
  parseRow,
  QueryParams,
} from 'views/StaticContent/SoftSkills/SoftSkills.helpers';
import styles from 'views/StaticContent/SoftSkills/SoftSkills.module.scss';
import { ItemsCount, Table } from '@spotted-zebra-uk/ui';
import {
  Button,
  Search,
  Select,
  Switch,
  TNotification,
  useDrawer,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

const SoftSkills: FC = () => {
  const { handleMsgType } = useNotification();

  const getSearchValue = (queryParams: URLSearchParams): string => {
    return queryParams.get(QueryParams.SEARCH) ?? '';
  };

  const getWithDeleted = (queryParams: URLSearchParams): boolean => {
    return (queryParams.get(QueryParams.WITH_DELETED) ?? 'false') === 'true';
  };

  const getCompanyId = (queryParams: URLSearchParams): number | null => {
    return Number(queryParams.get(QueryParams.COMPANY_ID)) || null;
  };

  const getDrawerSoftSkillId = (
    queryParams: URLSearchParams
  ): string | null => {
    return queryParams.get(QueryParams.EDIT_DRAWER);
  };

  const getIsAddMode = (queryParams: URLSearchParams): boolean => {
    return queryParams.get(QueryParams.ADD_DRAWER) === 'true';
  };

  const setIsAddMode = (isOpen: boolean): void => {
    if (isOpen) {
      queryParams.set(QueryParams.ADD_DRAWER, 'true');
    } else {
      queryParams.delete(QueryParams.ADD_DRAWER);
    }
    setQueryParams(queryParams);
  };

  const getPagination = (
    queryParams: URLSearchParams
  ): { page: number; pageSize: number } => {
    return {
      page: Number(queryParams.get(QueryParams.PAGE) ?? 1) - 1,
      pageSize:
        Number(queryParams.get(QueryParams.PAGE_SIZE)) ||
        DEFAULT_TABLE_PAGE_SIZE,
    };
  };

  const setCompanyId = (companyId: number | null | undefined): void => {
    setPagination({ page: 0, pageSize });
    if (!companyId) {
      queryParams.delete(QueryParams.COMPANY_ID);
    } else {
      queryParams.set(QueryParams.COMPANY_ID, String(companyId));
    }
    setQueryParams(queryParams);
  };

  const setWithDeleted = (withDeleted: boolean): void => {
    setPagination({ page: 0, pageSize });
    if (withDeleted) {
      queryParams.set(QueryParams.WITH_DELETED, 'true');
    } else {
      queryParams.delete(QueryParams.WITH_DELETED);
    }
    setQueryParams(queryParams);
  };

  const setPagination = (
    { page, pageSize }: { page: number; pageSize: number } | undefined = {
      pageSize: DEFAULT_TABLE_PAGE_SIZE,
      page: 0,
    }
  ) => {
    if (!page) {
      queryParams.delete(QueryParams.PAGE);
    } else {
      queryParams.set(QueryParams.PAGE, String(page + 1));
    }
    if (pageSize === DEFAULT_TABLE_PAGE_SIZE) {
      queryParams.delete(QueryParams.PAGE_SIZE);
    } else {
      queryParams.set(QueryParams.PAGE_SIZE, String(pageSize));
    }
    setQueryParams(queryParams);
  };

  const setSearchValue = (searchValue: string) => {
    setPagination({ page: 0, pageSize });
    if (searchValue) {
      queryParams.set(QueryParams.SEARCH, searchValue);
    } else {
      queryParams.delete(QueryParams.SEARCH);
    }
    setQueryParams(queryParams);
  };

  const setDrawerSoftSkillId = (softSkillSubId: string | null) => {
    if (softSkillSubId) {
      queryParams.set(QueryParams.EDIT_DRAWER, softSkillSubId);
      queryParams.delete(QueryParams.ADD_DRAWER);
    } else {
      queryParams.delete(QueryParams.EDIT_DRAWER);
    }
    setQueryParams(queryParams);
  };

  const { closeDrawer } = useDrawer();

  const debouncedSetSearchValue = debounce(
    setSearchValue,
    DEBOUNCE_TIME_DEFAULT
  );

  const location = useLocation();
  const [queryParams, setQueryParams] = useSearchParams(
    new URLSearchParams(location.search.slice(1))
  );
  const searchValue = getSearchValue(queryParams);
  const { page, pageSize } = getPagination(queryParams);
  const companyId = getCompanyId(queryParams);
  const withDeleted = getWithDeleted(queryParams);
  const drawerSoftSkillId = getDrawerSoftSkillId(queryParams);
  const isAddMode = getIsAddMode(queryParams);
  const rowCountRef = useRef(0);

  const { data, loading } = useSoftSkillFindManyPaginatedQuery({
    variables: {
      pagination: {
        // new Table component seems to
        // expect 0 based indexing for pages
        page: page + 1,
        size: pageSize,
      },
      filters: {
        companyId,
        withDeleted,
        ...(searchValue && { name: searchValue }),
      },
    },
    fetchPolicy: 'cache-and-network',
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const { data: companies, loading: companiesLoading } = useCompaniesQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const companyMap =
    companies?.Companies?.reduce(
      (acc, company) => ({ ...acc, [company.id]: company.name }),
      {} as Record<number, string>
    ) ?? {};

  const pageInfo = data?.SoftSkillFindManyPaginated?.pageInfo;

  const setDrawerSoftSkillIdCallback = useCallback(setDrawerSoftSkillId, [
    queryParams,
    setQueryParams,
  ]);

  const rows = useMemo(() => {
    const handleEditSoftSkillsDrawer = (softSkill: SoftSkillFragment) => {
      setDrawerSoftSkillIdCallback(softSkill.subId);
    };

    return data?.SoftSkillFindManyPaginated.data?.map(softSkill =>
      parseRow(softSkill, handleEditSoftSkillsDrawer)
    );
  }, [data?.SoftSkillFindManyPaginated.data, setDrawerSoftSkillIdCallback]);

  const rowCount = useMemo(() => {
    if (pageInfo?.itemsTotal !== undefined) {
      rowCountRef.current = pageInfo.itemsTotal;
    }
    return rowCountRef.current;
  }, [pageInfo?.itemsTotal]);

  return (
    <div
      className={styles.container}
      data-testid="soft-skills__table-container"
    >
      <h1 className={styles.heading}>Soft Skills</h1>
      <Table
        rows={rows}
        columns={COLUMNS}
        autosizeOnMount
        loading={loading}
        rowCount={rowCount}
        paginationMode="server"
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={setPagination}
        keepNonExistentRowsSelected
        emptyTableContent={<EmptyTableContent />}
        disableColumnFilter
        hasToolbar
        slotProps={{
          toolbar: {
            leftMainAction: <ItemsCount count={rowCount} text="Items" />,
            left: (
              <div className={styles.toolbarLeft}>
                <Select
                  id="company-selector"
                  options={getCompanyDropdownOptions(companyMap)}
                  onChange={({ value }) => setCompanyId(value)}
                  name="Owner"
                  className={styles.toolbarCompanySelector}
                  isLoading={companiesLoading}
                  onClearBtnClick={() => setCompanyId(null)}
                  placeholder="Select owner"
                  value={{
                    label: companyId ? companyMap[companyId] : '',
                    value: companyId,
                  }}
                />
              </div>
            ),
            right: (
              <div className={styles.toolbarRight}>
                <Switch
                  id="display-archived-toggle"
                  label="Display archived"
                  onToggle={setWithDeleted}
                  value={withDeleted}
                  size="large"
                />
                <Search
                  onInputChange={debouncedSetSearchValue}
                  value={{ label: searchValue, value: searchValue }}
                  className={styles.toolbarSearch}
                  isDisabled={false}
                />
              </div>
            ),
            rightMainAction: (
              <Button
                className={styles.toolbarAddButton}
                onClick={() => setIsAddMode(true)}
                variant="primary"
                size="medium"
                leftIcon={<Icon icon="add" className={styles.plusIcon} />}
              >
                Add skill
              </Button>
            ),
          },
        }}
        sx={{
          minHeight: '600px',
        }}
      />

      <EditSoftSkillDrawer
        onClose={() => {
          closeDrawer();
          setDrawerSoftSkillId(null);
        }}
        softSkillSubId={drawerSoftSkillId}
        isOpen={Boolean(drawerSoftSkillId)}
      />
      <AddSoftSkillDrawer
        isOpen={isAddMode && !drawerSoftSkillId}
        onClose={() => setIsAddMode(false)}
        setIsAddMode={setIsAddMode}
        setDrawerSoftSkillId={setDrawerSoftSkillId}
      />
    </div>
  );
};

export default SoftSkills;
