import {
  BroadSoftSkill,
  Orientation,
  SoftSkillCategory,
  SoftSkillFindManyPaginatedQuery,
  SoftSkillFragment,
  SoftSkillGranularity,
} from 'generated/graphql';
import styles from 'views/StaticContent/SoftSkills/SoftSkills.module.scss';
import { GridCellParams, GridValidRowModel } from '@spotted-zebra-uk/ui';
import { Button, TSelectOption } from '@spotted-zebra-uk/ui-components';

export enum QueryParams {
  SEARCH = 'search',
  PAGE = 'page',
  PAGE_SIZE = 'pageSize',
  WITH_DELETED = 'displayArchived',
  COMPANY_ID = 'companyId',
  EDIT_DRAWER = 'softSkill',
  ADD_DRAWER = 'addSoftSkill',
}

const DESCRIPTION_PLACEHOLDER = 'Missing description';
const TAXONOMY_VERSION_PLACEHOLDER = '1';
const OWNER_PLACEHOLDER = 'Global';
const UNEDITABLE_SKILLS: readonly string[] = [
  'cognitive inductive',
  'cognitive numerical',
  'cognitive verbal',
  'cognitive combined',
  'right for us',
  'right for the role',
  'right for the future',
] as const;

export type TRequiredSelectOption<T> = Required<
  Pick<TSelectOption<T>, 'label' | 'value'>
>;

type TSoftSkillFragmentReturnType = NonNullable<
  SoftSkillFindManyPaginatedQuery['SoftSkillFindManyPaginated']['data']
>[number];

export const isBroad = (
  softSkill: SoftSkillFragment | undefined
): softSkill is BroadSoftSkill & { __typename: 'BroadSoftSkill' } => {
  return softSkill?.__typename === 'BroadSoftSkill';
};

export const formatGranularity = (
  granularity: SoftSkillGranularity | undefined
): string => {
  switch (granularity) {
    case SoftSkillGranularity.Broad:
      return 'Broad';
    case SoftSkillGranularity.Focused:
    default:
      return 'Focused';
  }
};

export const formatCategory = (
  category: SoftSkillCategory | undefined
): string => {
  switch (category) {
    case SoftSkillCategory.RightForUs:
      return 'Right for us';
    case SoftSkillCategory.RightForTheFuture:
      return 'Right for the future';
    case SoftSkillCategory.CognitiveSummary:
      return 'Cognitive summary';
    default:
    case SoftSkillCategory.RightForTheRole:
      return 'Right for the role';
  }
};

export const formatOrientation = (orientation: Orientation): string => {
  switch (orientation) {
    case Orientation.Inverted:
      return 'Inverted';
    default:
    case Orientation.Standard:
      return 'Standard';
  }
};

export const DEFAULT_TABLE_PAGE_SIZE = 20;

export const COLUMNS = [
  {
    field: 'softSkillName',
    headerName: 'Name',
    width: 150,
    flex: 0.4,
    sortable: false,
    renderCell: ({
      value,
    }: GridCellParams<
      GridValidRowModel,
      { name: string; deletedAt: string }
    >) => {
      if (value?.deletedAt) {
        return <span className={styles.disabledCell}>{value.name}</span>;
      }
      return <span>{value?.name}</span>;
    },
  },
  {
    field: 'softSkillDescription',
    headerName: 'Description',
    width: 200,
    flex: 0.8,
    sortable: false,
    renderCell: ({
      value,
    }: GridCellParams<
      GridValidRowModel,
      { description: string; deletedAt: string }
    >) => {
      if (value?.deletedAt || !value?.description) {
        return (
          <span className={styles.disabledCell}>
            {value?.description || DESCRIPTION_PLACEHOLDER}
          </span>
        );
      }
      return <span>{String(value.description)}</span>;
    },
  },
  {
    field: 'softSkillTaxonomyVersion',
    headerName: 'Taxonomy',
    width: 100,
    flex: 0.2,
    sortable: false,
    renderCell: ({
      value,
    }: GridCellParams<GridValidRowModel, TSoftSkillFragmentReturnType>) => {
      if (value?.deletedAt) {
        return (
          <span className={styles.disabledCell}>
            {`v${value?.taxonomyVersion ?? TAXONOMY_VERSION_PLACEHOLDER}`}
          </span>
        );
      }
      return <span>{`v${value?.taxonomyVersion}`}</span>;
    },
  },
  {
    field: 'softSkillGranularity',
    headerName: 'Granularity',
    width: 150,
    flex: 0.3,
    sortable: false,
    renderCell: ({
      value,
    }: GridCellParams<GridValidRowModel, TSoftSkillFragmentReturnType>) => {
      return (
        <span className={value?.deletedAt ? styles.disabledCell : ''}>
          {formatGranularity(
            isBroad(value)
              ? SoftSkillGranularity.Broad
              : SoftSkillGranularity.Focused
          )}
        </span>
      );
    },
  },
  {
    field: 'softSkillCategory',
    headerName: 'Category',
    width: 150,
    flex: 0.3,
    sortable: false,
    renderCell: ({
      value,
    }: GridCellParams<GridValidRowModel, TSoftSkillFragmentReturnType>) => {
      if (value?.deletedAt || !value?.category) {
        return (
          <span className={styles.disabledCell}>
            {formatCategory(value?.category)}
          </span>
        );
      }
      return <span>{formatCategory(value.category)}</span>;
    },
  },
  {
    field: 'ownership',
    headerName: 'Ownership',
    width: 100,
    flex: 0.3,
    sortable: false,
    renderCell: ({
      value,
    }: GridCellParams<
      GridValidRowModel,
      { companyName: string; deletedAt: string }
    >) => {
      if (value?.deletedAt || !value?.companyName) {
        return (
          <span className={styles.disabledCell}>
            {value?.companyName ?? OWNER_PLACEHOLDER}
          </span>
        );
      }
      return <span>{String(value.companyName)}</span>;
    },
  },
  {
    field: 'edit',
    headerName: '',
    flex: 0.1,
    width: 100,
    sortable: false,
    renderCell: ({
      value,
    }: GridCellParams<
      GridValidRowModel,
      {
        softSkill: TSoftSkillFragmentReturnType;
        editCallback: (softSkill: TSoftSkillFragmentReturnType) => undefined;
      }
    >) => {
      if (
        UNEDITABLE_SKILLS.includes(
          value?.softSkill.name?.toLowerCase() as string
        )
      ) {
        return '';
      }
      return (
        <div className={styles.editButtonContainer}>
          <Button
            size="small"
            variant="secondary"
            onClick={() => value?.editCallback(value.softSkill)}
          >
            Edit
          </Button>
        </div>
      );
    },
  },
];

export const parseRow = (
  softSkill: TSoftSkillFragmentReturnType,
  editCallback: (softSkill: TSoftSkillFragmentReturnType) => void
) => {
  return {
    id: softSkill.id,
    _id: { id: softSkill.id, deletedAt: softSkill.deletedAt },
    subId: { subId: softSkill.subId, deletedAt: softSkill.deletedAt },
    softSkillName: { name: softSkill.name, deletedAt: softSkill.deletedAt },
    softSkillDescription: {
      description: softSkill.description,
      deletedAt: softSkill.deletedAt,
    },
    softSkillTaxonomyVersion: softSkill,
    softSkillGranularity: softSkill,
    ownership: {
      companyName: softSkill?.company?.name,
      deletedAt: softSkill.deletedAt,
    },
    softSkillCategory: softSkill,
    edit: { softSkill, editCallback },
    _softSkill: softSkill,
  };
};

export const getCompanyDropdownOptions = (
  companyMap: Record<number, string>
): TSelectOption<number | null>[] => {
  return [
    { label: 'Global', value: null },
    ...[...Object.entries(companyMap)]
      .map(
        ([companyId, companyName]): TSelectOption<number> => ({
          value: Number(companyId),
          label: companyName,
        })
      )
      .sort((a, b) => ((a.label as string) < (b.label as string) ? -1 : 1)),
  ];
};
