import Icon from 'components/atoms/Icon';
import { DEBOUNCE_TIME_DEFAULT } from 'constants/debounce';
import {
  SortOption,
  StackOneJobsFindManyPaginatedDocument,
  StackOneJobSortField,
  useStackOneJobsFindManyPaginatedQuery,
  useStackOneLinkProjectMutation,
} from 'generated/graphql';
import { useUrlState } from 'hooks/useUrlState';
import { debounce } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { GridRowSelectionModel, ItemsCount, Table } from '@spotted-zebra-uk/ui';
import {
  Button,
  Search,
  TMultiselectOption,
  TNotification,
  TSelectOption,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from '../AtsLinks.module.scss';
import { EmptyTableContent } from '../EmptyTableContent';
import { FilterFormValues, FilteringModal } from '../FilteringModal';
import { LinkingModal } from './LinkingModal';
import { COLUMNS, parseRowData } from './UnlinkedTab.helpers';

const DEFAULT_PAGE_SIZE = 10;

type LinkedTabProps = {
  selectedCompanyId: number;
  selectedCompanyName: string;
  onRefetch: () => void;
};

export type FilteringArgs = {
  dateFrom: string | undefined;
  dateTo: string | undefined;
  departments: TMultiselectOption[] | undefined;
};

export function UnlinkedTab({
  selectedCompanyId,
  selectedCompanyName,
  onRefetch,
}: LinkedTabProps) {
  const [searchValue, setSearchValue] = useUrlState('searchQueryUnlinked');

  const [linkProject, { loading: loadingLinking }] =
    useStackOneLinkProjectMutation({
      refetchQueries: [StackOneJobsFindManyPaginatedDocument],
    });

  const [filteringArgs, setFilteringArgs] = useState<FilteringArgs>({
    dateFrom: undefined,
    dateTo: undefined,
    departments: undefined,
  });

  const { handleMsgType } = useNotification();

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const selectedRowsCountStr = String(selectedRows.length);

  const [modalOpenLinking, setModalOpenLinking] = useState(false);
  const [modalOpenFiltering, setModalOpenFiltering] = useState(false);

  const rowCountRef = useRef(0);

  const dateFilterApplied = Boolean(
    filteringArgs.dateFrom || filteringArgs.dateTo
  );

  const departmentFilter = filteringArgs?.departments?.length
    ? filteringArgs.departments.map(dp => dp.value as string)
    : undefined;

  const appliedFiltersCount =
    Number(dateFilterApplied) + Number(Boolean(departmentFilter));

  const {
    data,
    loading: loadingTable,
    refetch,
  } = useStackOneJobsFindManyPaginatedQuery({
    variables: {
      companyId: selectedCompanyId,
      take: pagination.pageSize,
      skip: pagination.pageSize * pagination.page,
      hasProjectLinked: false,
      search: searchValue,
      sortField: StackOneJobSortField.Title,
      sortOrder: SortOption.Asc,
      ...(departmentFilter && {
        departmentIds: departmentFilter,
      }),
      dateFrom: filteringArgs.dateFrom,
      dateTo: filteringArgs.dateTo,
    },
    fetchPolicy: 'cache-and-network',
    skip: !Boolean(selectedCompanyId),
  });

  const pageInfo = data?.stackOneJobsFindManyPaginated?.pageInfo;

  function handleOpenModalLinking() {
    setModalOpenLinking(true);
  }

  function handleCloseModalLinking() {
    setModalOpenLinking(false);
  }

  function handleClearRowSelection() {
    setSelectedRows([]);
  }

  function handleConfirmModalLinking(projectForLinking: TSelectOption) {
    linkProject({
      variables: {
        companyId: selectedCompanyId,
        projectId: Number(projectForLinking.value),
        jobIds: selectedRows.map(jobId => String(jobId)),
      },
      onCompleted: () => {
        handleMsgType({
          type: TNotification.success,
          title: `${selectedRowsCountStr} requsitions successfully linked to ${projectForLinking.label}`,
          message: 'Find them in ‘Linked requisitions’',
        });
        refetch();
        onRefetch();
        handleClearRowSelection();
        handleCloseModalLinking();
      },
      onError: () => {
        handleMsgType({
          type: TNotification.error,
          title: `Something went wrong. Please try again.`,
        });
        handleCloseModalLinking();
      },
    });
  }

  function handleConfirmModalFiltering(filteringParams: FilterFormValues) {
    setFilteringArgs({
      dateFrom: filteringParams.dateFrom,
      dateTo: filteringParams.dateTo,
      departments: filteringParams.departments,
    });
    setModalOpenFiltering(false);
  }

  function handleSearchInputChange(searchValue: string) {
    setSearchValue(searchValue);
  }

  const debouncedInputChange = debounce(
    handleSearchInputChange,
    DEBOUNCE_TIME_DEFAULT
  );

  const rows = useMemo(() => parseRowData(data), [data]);

  // The value rowCount becomes undefined during loading, it will reset the page to zero.
  // To avoid this issue, we can memoize the rowCount value to ensure it doesn't change during loading
  // More info: https://mui.com/x/react-data-grid/pagination/#index-based-pagination
  const rowCount = useMemo(() => {
    if (pageInfo?.itemsTotal !== undefined) {
      rowCountRef.current = pageInfo.itemsTotal;
    }
    return rowCountRef.current;
  }, [pageInfo?.itemsTotal]);

  const selectedRowsCount = selectedRows.length;
  const isLinkingBtnDisabled = selectedRowsCount === 0;
  const isSearchInputDisabled = !Boolean(selectedCompanyId);
  const shouldOpenLinkingModal = Boolean(modalOpenLinking && selectedCompanyId);
  const shouldOpenFilterModal = Boolean(
    modalOpenFiltering && selectedCompanyId
  );

  return (
    <>
      <Table
        rows={rows}
        columns={COLUMNS}
        autosizeOnMount
        autosizeOptions={{
          expand: true,
          columns: ['status'],
        }}
        loading={loadingTable}
        rowCount={rowCount}
        paginationMode="server"
        paginationModel={pagination}
        onPaginationModelChange={setPagination}
        checkboxSelection
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={selectOptions =>
          setSelectedRows(selectOptions)
        }
        keepNonExistentRowsSelected
        emptyTableContent={<EmptyTableContent />}
        disableColumnFilter
        hasToolbar
        slotProps={{
          toolbar: {
            leftMainAction: <ItemsCount count={rowCount} text="Items" />,
            ...(selectedRows.length && {
              left: (
                <ItemsCount
                  count={selectedRows.length}
                  text="Selected"
                  dismissible
                  onClick={handleClearRowSelection}
                />
              ),
            }),
            right: (
              <>
                <Search
                  onInputChange={debouncedInputChange}
                  value={{ label: searchValue || '', value: searchValue || '' }}
                  className={styles.toolbarSearch}
                  isDisabled={isSearchInputDisabled}
                />
                <Button
                  variant="secondary"
                  leftIcon={<Icon icon="filter" />}
                  disabled={!Boolean(selectedCompanyId)}
                  onClick={() => setModalOpenFiltering(true)}
                  className={styles.filterBtn}
                  counter={appliedFiltersCount || undefined}
                >
                  Filter
                </Button>
              </>
            ),
            rightMainAction: (
              <Button
                onClick={handleOpenModalLinking}
                disabled={isLinkingBtnDisabled}
              >
                Link to project
              </Button>
            ),
          },
        }}
        sx={{
          minHeight: '600px',
        }}
      />
      {shouldOpenLinkingModal && (
        <LinkingModal
          companyId={selectedCompanyId}
          isOpen={shouldOpenLinkingModal}
          loadingLinking={loadingLinking}
          onClose={handleCloseModalLinking}
          onConfirm={(projectForLinking: TSelectOption) =>
            handleConfirmModalLinking(projectForLinking)
          }
          selectedCompanyName={selectedCompanyName || ''}
          selectedJobsCount={selectedRowsCount}
        />
      )}
      {shouldOpenFilterModal && (
        <FilteringModal
          companyId={selectedCompanyId}
          isOpen={shouldOpenFilterModal}
          onClose={() => setModalOpenFiltering(false)}
          onConfirm={handleConfirmModalFiltering}
          activeFilters={filteringArgs}
        />
      )}
    </>
  );
}
