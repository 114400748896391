import { StackOneJobsFindManyPaginatedQuery } from 'generated/graphql';
import { formatDate, formatISO } from 'helpers/date';
import { GridCellParams, Stack } from '@spotted-zebra-uk/ui';
import { Tag, TagColor } from '@spotted-zebra-uk/ui-components';
import styles from '../AtsLinks.module.scss';

export function parseRowData(
  data: StackOneJobsFindManyPaginatedQuery | undefined
) {
  if (!data) return [];

  return data.stackOneJobsFindManyPaginated.data?.map(dataPoint => ({
    id: dataPoint.job.id,
    created: formatDate(formatISO(dataPoint.job.createdAt), 'dd MMM yyyy'),
    name: dataPoint.job.title,
    code: dataPoint.job.code,
    departments: '',
    // TODO: fix after BE fix
    // departments: dataPoint.job?.departments
    //   ?.map(department => department.name)
    //   ?.join(', '),
    status: dataPoint.job.jobStatus,
  }));
}

export const COLUMNS = [
  { field: 'created', headerName: 'Created' },
  { field: 'name', headerName: 'Name', flex: 0.45, maxWidth: 300 },
  { field: 'code', headerName: 'Code', flex: 0.1, maxWidth: 100 },
  {
    field: 'departments',
    headerName: 'Departments',
    flex: 0.45,
    maxWidth: 300,
    renderCell: (params: GridCellParams) => {
      const { value } = params;

      if (value) return String(value);

      return <span className={styles.disabledCell}>-</span>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (params: GridCellParams) => {
      const { value } = params;

      const valueStr = String(value);

      if (
        ['published', 'internal', 'open'].includes(valueStr.toLocaleLowerCase())
      ) {
        return (
          <Stack height="100%" justifyContent="center">
            <Tag
              tagColor={TagColor.GREEN}
              isDismissible={false}
              isPresentational
            >
              {String(value)}
            </Tag>
          </Stack>
        );
      }

      if (['draft', 'pending'].includes(valueStr.toLocaleLowerCase())) {
        return (
          <Stack height="100%" justifyContent="center">
            <Tag
              tagColor={TagColor.YELLOW}
              isDismissible={false}
              isPresentational
            >
              {String(value)}
            </Tag>
          </Stack>
        );
      }

      return <span className={styles.disabledCell}>Unmapped</span>;
    },
  },
];
