import { InfoItem } from 'components/molecules/InfoItem/InfoItem';
import { InterviewStageFragment } from 'generated/graphql';
import { getStageDate } from 'views/Project/components/Stages/StageOverview/StageOverview.helpers';
import styles from './InterviewStageOverview.module.scss';

const InterviewStageOverview = ({
  interviewStages,
}: {
  interviewStages: InterviewStageFragment[];
}) => {
  return (
    <div className={styles.container}>
      {interviewStages.map(interviewStage => (
        <div key={interviewStage.subId} className={styles.row}>
          <InfoItem label="SubId" value={interviewStage.subId} />
          <InfoItem
            label="Date created"
            value={getStageDate(interviewStage.createdAt)}
          />
        </div>
      ))}
    </div>
  );
};

export default InterviewStageOverview;
