import clsx from 'clsx';
import Icon from 'components/atoms/Icon';
import { TJobRoleFormValues } from 'components/feature/jobRole/JobRoleForm/JobRoleForm.types';
import { FormikSoftSkillNameSelectFormField } from 'components/feature/jobRole/SoftSkillNameSelectFormField/SoftSkillNameSelectFormField';
import TraitsModal from 'components/feature/jobRole/TraitsModal/TraitsModal';
import { useField, useFormikContext } from 'formik';
import { SoftSkillFragment, SoftSkillType } from 'generated/graphql';
import { FC, KeyboardEvent } from 'react';
import {
  Button,
  FormikCheckboxFormField,
  FormikSelectFormField,
  FormikTextInputField,
} from '@spotted-zebra-uk/ui-components';
import styles from './JobRoleFormSoftSkillField.module.scss';

export const softSkillTypeOptions = [
  {
    value: SoftSkillType.Differentiator,
    label: 'DIFFERENTIATOR',
  },
  {
    value: SoftSkillType.Core,
    label: 'CORE',
  },
  {
    value: SoftSkillType.Diversity,
    label: 'DIVERSITY',
  },
];

export type TJobRoleFormSoftSkillField = {
  openTraitsModal: (softSkillIndex: number) => void;
  traitsModalSoftSkillIndex?: number;
  onCloseTraitsModal: () => void;
  index: number;
  remove: (index: number) => void;
  predefinedSoftSkills?: SoftSkillFragment[];
};

const JobRoleFormSoftSkillField: FC<TJobRoleFormSoftSkillField> = ({
  openTraitsModal,
  traitsModalSoftSkillIndex,
  onCloseTraitsModal,
  index,
  remove,
  predefinedSoftSkills,
}) => {
  const { values, setFieldValue } = useFormikContext<TJobRoleFormValues>();

  const handleCustomWeightsChange = (newValue: string) => {
    // TODO move to form validation
    // This solution set prev value if the new greater than 100
    setFieldValue(
      `softSkills.${index}.weight`,
      Number(newValue) <= 100 ? newValue : values.softSkills[index].weight
    );
  };

  const handleCustomWeightsKeyPress = (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    // need this to prevent keyboard typing
    if (['e', 'E', '+', '-', ','].includes(event.key)) event.preventDefault();
  };

  // TODO: Remove all form field wrappers after update dependencies.
  return (
    <div className={styles.skillsListItem}>
      <div
        className={styles.fieldWrapper}
        data-testid="sp-soft-skill-name-wrapper"
      >
        {values.company.value ? (
          <FormikSoftSkillNameSelectFormField
            id={`softSkills.${index}.softSkillId`}
            label="Name"
            companyId={values.company.value}
            placeholder="Name"
            predefinedSoftSkills={predefinedSoftSkills}
            maxMenuHeight={240}
            index={index}
          />
        ) : null}
      </div>
      <div
        className={clsx(styles.fieldWrapper, styles.typeSelect)}
        data-testid="sp-soft-skill-type-wrapper"
      >
        <FormikSelectFormField
          id={`softSkills.${index}.type`}
          label="Type"
          options={softSkillTypeOptions}
          placeholder="Type"
          useFormikField={useField}
          hasClearIndicator={false}
        />
      </div>
      {values.customWeights && (
        <FormikTextInputField
          id={`softSkills.${index}.weight`}
          label="Weight"
          placeholder="Weight"
          type="number"
          onChange={handleCustomWeightsChange}
          onKeyPress={handleCustomWeightsKeyPress}
          className={styles.weightInput}
          data-testid="sp-soft-skill-weight-input"
          useFormikField={useField}
        />
      )}
      <div
        className={clsx(
          styles.fieldWrapper,
          styles.includeInCalibrationCheckbox
        )}
        data-testid="sp-soft-skill-include-in-calibration-wrapper"
      >
        <FormikCheckboxFormField
          id={`softSkills.${index}.includeInCalibration`}
          label="Include In Calibration"
          useFormikField={useField}
        />
      </div>
      <Button
        onClick={() => openTraitsModal(index)}
        size="medium"
        variant="secondary"
        type="button"
        disabled={!values.softSkills[index].softSkillId.value}
        data-role="open-traits-modal"
      >
        Set sub skills
      </Button>
      <Button
        onClick={() => remove(index)}
        size="medium"
        variant="negative"
        type="button"
        leftIcon={<Icon icon="delete_outline" className={styles.icon} />}
      />
      {traitsModalSoftSkillIndex === index && (
        <TraitsModal
          onClose={onCloseTraitsModal}
          softSkillIndex={traitsModalSoftSkillIndex}
        />
      )}
    </div>
  );
};

export default JobRoleFormSoftSkillField;
