import {
  SortOption,
  StackOneDepartmentFindManyPaginatedQuery,
  StackOneDepartmentSortField,
  useStackOneDepartmentFindManyPaginatedQuery,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import {
  TMultiselectOption,
  TNotification,
  TSelectOption,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

export function useDepartmentsSelect(companyId: number | undefined) {
  const isEnabled = companyId !== undefined;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [departmentsForSelect, setDepartmentsForSelect] = useState<
    TMultiselectOption[]
  >([]);

  const { handleMsgType } = useNotification();

  const { loading, data } = useStackOneDepartmentFindManyPaginatedQuery({
    skip: !isEnabled,
    variables: {
      companyId: companyId ?? -1,
      take: 100,
      skip: (currentPage - 1) * 100,
      search: searchValue,
      sortField: StackOneDepartmentSortField.Name,
      sortOrder: SortOption.Asc,
    },
    onCompleted: data => {
      const parsedData = parseDataForSelect(data);
      setDepartmentsForSelect(_prev => [..._prev, ...parsedData]);
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: `${error.message}`,
      });
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setDepartmentsForSelect([]);
    setCurrentPage(1);
  }, [searchValue]);

  useEffect(() => {
    setCurrentPage(1);
    setSearchValue('');
    setDepartmentsForSelect([]);
  }, [companyId]);

  const itemsTotal =
    data?.stackOneDepartmentFindManyPaginated?.pageInfo?.itemsTotal || 0;

  const hasLoadMore = itemsTotal > departmentsForSelect.length;

  return {
    selectOptions: departmentsForSelect,
    loading,
    hasMoreItems: hasLoadMore,
    loadMore: () => setCurrentPage(_prev => _prev + 1),
    searchValue,
    setSearchValue,
  };
}

function parseDataForSelect(
  data: StackOneDepartmentFindManyPaginatedQuery | undefined
): TSelectOption[] {
  if (!data || !data.stackOneDepartmentFindManyPaginated.data) {
    return [];
  }

  return data.stackOneDepartmentFindManyPaginated.data.map(department => ({
    value: String(department.id),
    label: `${department.name}`,
    labelNote: `${department.id}`,
  }));
}
