import { ModalTypes } from 'enums/modalTypes';
import { useUpdateCompanyMutation } from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import {
  Button,
  InlineNotification,
  Modal,
  ModalSize,
  NotificationType,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './UpdateCognitiveTestsBlocked.module.scss';

export type IUpdateCognitiveTestsBlockedModal = {
  id: number;
  name: string;
  areCognitiveTestsBlocked: boolean;
  onSubmit: (value: boolean) => void;
};

const UpdateCognitiveTestsBlockedModal = ({
  areCognitiveTestsBlocked,
  id,
  name,
  onSubmit,
}: IUpdateCognitiveTestsBlockedModal) => {
  const { handleMsgType } = useNotification();

  const handleCloseModal = () => {
    closeModal(ModalTypes.UPDATE_COGNITIVE_TESTS_BLOCKED);
  };

  const [editCompanyMutation, { loading }] = useUpdateCompanyMutation({
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        title: `Cognitive tests were ${
          areCognitiveTestsBlocked ? 'enabled' : 'disabled'
        }`,
      });
      onSubmit(areCognitiveTestsBlocked);
      handleCloseModal();
    },
    onError: () => {
      handleMsgType({
        type: TNotification.error,
        title: `Could not ${
          areCognitiveTestsBlocked ? 'enable' : 'disable'
        } cognitive tests`,
        message: 'Something went wrong. Please try again.',
      });
      handleCloseModal();
    },
  });

  const handleSubmit = () => {
    editCompanyMutation({
      variables: {
        id,
        name,
        areCognitiveTestsBlocked: !areCognitiveTestsBlocked,
      },
    });
  };

  return (
    <Modal
      onClose={handleCloseModal}
      isOpen
      modalSize={ModalSize.SMALL}
      className={styles.modalContainer}
      header={`Confirm ${
        areCognitiveTestsBlocked ? 'enabling' : 'disabling'
      } cognitive tests`}
    >
      <InlineNotification
        notificationType={NotificationType.NEUTRAL}
        className={styles.cognitiveTestsBlockedInlineNotification}
      >
        The change will {areCognitiveTestsBlocked ? 'enable' : 'disable'} the
        creation of cognitive tests for new projects created for this company,
        through the RSP builder.
      </InlineNotification>
      <div className={styles.actionsContainer}>
        <Button
          onClick={handleCloseModal}
          size="medium"
          variant="secondary"
          data-testid="update-cognitive-tests-blocked__cancel-button"
        >
          Cancel
        </Button>
        <Button
          size="medium"
          type="submit"
          loading={loading}
          onClick={handleSubmit}
          data-testid="update-cognitive-tests-blocked__confirm-button"
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateCognitiveTestsBlockedModal;
