import Icon from 'components/atoms/Icon';
import { DEBOUNCE_TIME_DEFAULT } from 'constants/debounce';
import {
  JobProjectLink,
  SortOption,
  StackOneJobsFindManyPaginatedDocument,
  StackOneJobSortField,
  useStackOneJobsFindManyPaginatedQuery,
  useStackOneUnlinkJobsMutation,
} from 'generated/graphql';
import { useUrlState } from 'hooks/useUrlState';
import { debounce } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { GridRowSelectionModel, ItemsCount, Table } from '@spotted-zebra-uk/ui';
import {
  Button,
  Search,
  TMultiselectOption,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from '../AtsLinks.module.scss';
import { EmptyTableContent } from '../EmptyTableContent';
import { FilterFormValues, FilteringModal } from '../FilteringModal';
import { COLUMNS, parseRowData } from './LinkedTab.helpers';
import { UnlinkingModal } from './UnlinkingModal';

const DEFAULT_PAGE_SIZE = 10;

type LinkedTabProps = {
  selectedCompanyId: number;
  selectedCompanyName: string;
  onRefetch: () => void;
};

export type FilteringArgs = {
  dateFrom: string | undefined;
  dateTo: string | undefined;
  departments: TMultiselectOption[] | undefined;
};

export function LinkedTab({
  selectedCompanyId,
  selectedCompanyName,
  onRefetch,
}: LinkedTabProps) {
  const [searchValue, setSearchValue] = useUrlState('searchQueryLinked');

  const [unlinkJobs, { loading: loadingUnlinking }] =
    useStackOneUnlinkJobsMutation({
      refetchQueries: [StackOneJobsFindManyPaginatedDocument],
    });

  const [filteringArgs, setFilteringArgs] = useState<FilteringArgs>({
    dateFrom: undefined,
    dateTo: undefined,
    departments: undefined,
  });

  const { handleMsgType } = useNotification();

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [selectedRowsDetailed, setSelectedRowsDetailed] = useState<TableRow>(
    []
  );

  const selectedRowsCountStr = String(selectedRows.length);

  const [modalOpenUnlinking, setModalOpenUnlinking] = useState(false);
  const [modalOpenFiltering, setModalOpenFiltering] = useState(false);

  const rowCountRef = useRef(0);

  const dateFilterApplied = Boolean(
    filteringArgs.dateFrom || filteringArgs.dateTo
  );

  const departmentFilter = filteringArgs?.departments?.length
    ? filteringArgs.departments.map(dp => dp.value as string)
    : undefined;

  const appliedFiltersCount =
    Number(dateFilterApplied) + Number(Boolean(departmentFilter));

  const { data, loading: loadingTable } = useStackOneJobsFindManyPaginatedQuery(
    {
      variables: {
        companyId: selectedCompanyId,
        take: pagination.pageSize,
        skip: pagination.pageSize * pagination.page,
        hasProjectLinked: true,
        search: searchValue,
        sortField: StackOneJobSortField.Title,
        sortOrder: SortOption.Asc,
        ...(departmentFilter && {
          departmentIds: departmentFilter,
        }),
        dateFrom: filteringArgs.dateFrom,
        dateTo: filteringArgs.dateTo,
      },
      fetchPolicy: 'cache-and-network',
      skip: !Boolean(selectedCompanyId),
    }
  );

  const pageInfo = data?.stackOneJobsFindManyPaginated?.pageInfo;

  function handleOpenModalUnlinking() {
    setModalOpenUnlinking(true);
  }

  function handleCloseModalUnlinking() {
    setModalOpenUnlinking(false);
  }

  function handleClearSelection() {
    setSelectedRows([]);
  }

  function handleConfirmModalUnkinking() {
    const jobProjectLinks: JobProjectLink[] = selectedRowsDetailed.map(row => ({
      jobId: row.id,
      projectId: Number(row.projectId),
    }));

    unlinkJobs({
      variables: {
        jobProjectLinks: jobProjectLinks,
      },
      onCompleted: () => {
        handleMsgType({
          type: TNotification.success,
          title: `${selectedRowsCountStr} requsitions successfully unlinked`,
          message: 'Find them in ‘Unlinked requisitions’',
        });
        onRefetch();
        handleClearSelection();
        handleCloseModalUnlinking();
      },
      onError: () => {
        handleMsgType({
          type: TNotification.error,
          title: `Requisitions could not be unlinked.`,
          message: 'Something went wrong. Please try again.',
        });
        handleCloseModalUnlinking();
      },
    });
  }

  function handleConfirmModalFiltering(filteringParams: FilterFormValues) {
    setFilteringArgs({
      dateFrom: filteringParams.dateFrom,
      dateTo: filteringParams.dateTo,
      departments: filteringParams.departments,
    });
    setModalOpenFiltering(false);
  }

  function handleSearchInputChange(searchValue: string) {
    setSearchValue(searchValue);
  }

  const debouncedInputChange = debounce(
    handleSearchInputChange,
    DEBOUNCE_TIME_DEFAULT
  );

  const rows = useMemo(() => parseRowData(data), [data]);

  const rowCount = useMemo(() => {
    if (pageInfo?.itemsTotal !== undefined) {
      rowCountRef.current = pageInfo.itemsTotal;
    }

    return rowCountRef.current;
  }, [pageInfo?.itemsTotal]);

  const selectedRowsCount = selectedRows.length;
  const isUnlinkingBtnDisabled = selectedRowsCount === 0;
  const isSearchInputDisabled = !Boolean(selectedCompanyId);
  const shouldOpenLinkingModal = Boolean(
    modalOpenUnlinking && selectedCompanyId
  );
  const shouldOpenFilterModal = Boolean(
    modalOpenFiltering && selectedCompanyId
  );

  return (
    <>
      <Table
        rows={rows}
        columns={COLUMNS}
        autosizeOnMount
        autosizeOptions={{
          expand: true,
          columns: ['project', 'projectId'],
        }}
        loading={loadingTable}
        rowCount={rowCount}
        paginationMode="server"
        paginationModel={pagination}
        onPaginationModelChange={setPagination}
        checkboxSelection
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(selectOptions, details) => {
          // Needed only for MUI selection to work (rowSelectionModel)
          setSelectedRows(selectOptions);
          // For our API purposes
          const selectedRowsDetailed = selectOptions.map(jobId =>
            details.api.getRow(jobId)
          );
          setSelectedRowsDetailed(selectedRowsDetailed);
        }}
        keepNonExistentRowsSelected
        emptyTableContent={<EmptyTableContent />}
        disableColumnFilter
        hasToolbar
        slotProps={{
          toolbar: {
            leftMainAction: <ItemsCount count={rowCount} text="Items" />,
            ...(selectedRowsCount && {
              left: (
                <ItemsCount
                  count={selectedRowsCount}
                  text="Selected"
                  dismissible
                  onClick={handleClearSelection}
                  ariaLabelSuffix="selected"
                />
              ),
            }),
            rightMainAction: (
              <Button
                onClick={handleOpenModalUnlinking}
                disabled={isUnlinkingBtnDisabled}
              >
                Unlink projects
              </Button>
            ),
            right: (
              <>
                <Search
                  onInputChange={debouncedInputChange}
                  value={{ label: searchValue || '', value: searchValue || '' }}
                  className={styles.toolbarSearch}
                  isDisabled={isSearchInputDisabled}
                />
                <Button
                  variant="secondary"
                  leftIcon={<Icon icon="filter" />}
                  disabled={!Boolean(selectedCompanyId)}
                  onClick={() => setModalOpenFiltering(true)}
                  className={styles.filterBtn}
                  counter={appliedFiltersCount || undefined}
                >
                  Filter
                </Button>
              </>
            ),
          },
        }}
        sx={{
          minHeight: '600px',
        }}
      />
      {shouldOpenLinkingModal && (
        <UnlinkingModal
          companyId={selectedCompanyId}
          isOpen={shouldOpenLinkingModal}
          loadingLinking={loadingUnlinking}
          onClose={handleCloseModalUnlinking}
          onConfirm={handleConfirmModalUnkinking}
          selectedCompanyName={selectedCompanyName || ''}
          selectedJobsCount={selectedRowsCount}
        />
      )}
      {shouldOpenFilterModal && (
        <FilteringModal
          companyId={selectedCompanyId}
          isOpen={shouldOpenFilterModal}
          onClose={() => setModalOpenFiltering(false)}
          onConfirm={handleConfirmModalFiltering}
          activeFilters={filteringArgs}
        />
      )}
    </>
  );
}

type TableRow = {
  id: string;
  linked: string;
  created: string;
  name: string;
  code: string;
  departments: string;
  status: string;
  project: string;
  projectId: string;
}[];
