import {
  StageFindManyDocument,
  StageFindManyQuery,
  StageFindManyQueryVariables,
  useRemoveOneStageMutation,
} from 'generated/graphql';
import { FC, useState } from 'react';
import {
  Button,
  IconType,
  Modal,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './DeleteStage.module.scss';

interface IDeleteStage {
  projectId: number;
  id: number;
}

const DeleteStage: FC<IDeleteStage> = props => {
  const { handleMsgType } = useNotification();
  const [modalOpen, setModalOpen] = useState(false);

  // User should be able to delete stage.
  const [deleteStageMutation] = useRemoveOneStageMutation({
    onCompleted: () => {
      setModalOpen(false);
    },
    update(cache, { data }) {
      // Stage should be removed from cache.
      const isCompleted = !!data?.RemoveOneStage;
      if (isCompleted) {
        const cacheData = cache.readQuery<
          StageFindManyQuery,
          StageFindManyQueryVariables
        >({
          query: StageFindManyDocument,
          variables: {
            projectId: props.projectId,
          },
        });
        if (cacheData?.Stages) {
          cache.writeQuery({
            query: StageFindManyDocument,
            variables: {
              projectId: props.projectId,
            },
            data: {
              Stages: [],
            },
          });
        }
      }
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const handleDelete = () => {
    deleteStageMutation({
      variables: {
        stageId: props.id,
      },
    });
  };

  return (
    <>
      <Button
        onClick={() => setModalOpen(true)}
        size="medium"
        variant="secondary"
        data-testid="stage-header__delete-stage-button"
      >
        Remove
      </Button>
      <Modal
        header="Are you sure?"
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        headerIconType={IconType.WARNING}
        buttonsProps={{
          confirmButtonChildren: 'Delete',
          onConfirmButtonClick: handleDelete,
          confirmButtonProps: {
            className: styles.modalBtn,
          },
        }}
      >
        <p className={styles.modalContentRow}>
          You are about to remove the <span>assessment stage</span>. This action
          cannot be undone.
        </p>
        <p className={styles.modalContentRow}>
          All results from this project will be deleted. Candidates will lose
          access to the assessment but remain in the project.
        </p>
      </Modal>
    </>
  );
};

export default DeleteStage;
