import {
  Orientation,
  SoftSkillCategory,
  SoftSkillFragment,
  SoftSkillGranularity,
} from 'generated/graphql';
import {
  formatCategory,
  formatGranularity,
  formatOrientation,
  isBroad,
  TRequiredSelectOption,
} from 'views/StaticContent/SoftSkills/SoftSkills.helpers';
import * as yup from 'yup';
import { TSelectOption } from '@spotted-zebra-uk/ui-components';

export const softSkillCategoryOptions: TSelectOption<SoftSkillCategory>[] = [
  {
    label: formatCategory(SoftSkillCategory.RightForUs),
    value: SoftSkillCategory.RightForUs,
  },
  {
    label: formatCategory(SoftSkillCategory.RightForTheRole),
    value: SoftSkillCategory.RightForTheRole,
  },
  {
    label: formatCategory(SoftSkillCategory.RightForTheFuture),
    value: SoftSkillCategory.RightForTheFuture,
  },
  {
    label: formatCategory(SoftSkillCategory.CognitiveSummary),
    value: SoftSkillCategory.CognitiveSummary,
  },
] as const;

export const softSkillGranularityOptions: TSelectOption<SoftSkillGranularity>[] =
  [
    {
      label: formatGranularity(SoftSkillGranularity.Focused),
      value: SoftSkillGranularity.Focused,
    },
    {
      label: formatGranularity(SoftSkillGranularity.Broad),
      value: SoftSkillGranularity.Broad,
    },
  ] as const;

export type TUpsertSoftSkillArgs = {
  trait: TRequiredSelectOption<number>;
  orientation: TRequiredSelectOption<Orientation>;
  weight: number;
};

export type TEditSoftSkillFormValues = {
  _id: number;
  subId: string;
  name: string;
  description?: string;
  owner: TRequiredSelectOption<number | null>;
  category: TRequiredSelectOption<SoftSkillCategory>;
  granularity: string;
  taxonomyVersion: number;
  softSkillTraits: TUpsertSoftSkillArgs[];
  focusedSoftSkills: TRequiredSelectOption<string>[];
  broadSoftSkills: TRequiredSelectOption<string>[];
};

export const editSoftSkillValidationSchema = yup.object().shape({
  name: yup
    .string()
    .ensure()
    .min(3, "Soft skill name can't be shorter than 3 characters!")
    .max(40, "Soft skill name can't be longer than 40 characters!")
    .required('Soft skill name is required')
    .test(
      'name-sentence-case',
      'Name must be sentence case!',
      softSkillName => {
        const head = softSkillName.charAt(0);
        const tail = softSkillName.slice(1);
        return (
          head === head.toLocaleUpperCase() && tail === tail.toLocaleLowerCase()
        );
      }
    )
    .test(
      'name-whitespace',
      'Name must not have leading or trailing whitespace!',
      softSkillName => {
        return !softSkillName.startsWith(' ') && !softSkillName.endsWith(' ');
      }
    ),
  description: yup
    .string()
    .min(10, "Description can't be shorter than 10 characters!")
    .max(200, "Description can't be longer than 200 characters!")
    .test(
      'description-sentence-case',
      'Description must be sentence case!',
      description => {
        if (!description) return true;
        const head = description.charAt(0);
        const tail = description.slice(1);
        return (
          head === head.toLocaleUpperCase() && tail === tail.toLocaleLowerCase()
        );
      }
    )
    .test(
      'description-whitespace',
      'Description must not have leading or trailing whitespace!',
      description => {
        if (!description) return true;
        return !description.startsWith(' ') && !description.endsWith(' ');
      }
    ),
  taxonomyVersion: yup
    .number()
    .integer()
    .min(1, 'Minimum version number is 1!')
    .max(2, 'Maximum version number is 2!'),
  softSkillTraits: yup
    .array()
    .of(
      yup.object().shape({
        weight: yup
          .number()
          .min(0, 'Weight must be >= 0!')
          .max(1, 'Weight must be <= 1!')
          .required('Weight is required!'),
        orientation: yup.object().shape({
          label: yup.string().required(),
          value: yup
            .mixed<Orientation>()
            .oneOf(Object.values(Orientation), 'Orientation is invalid!')
            .required('Orientation is required!'),
        }),
      })
    )
    .required()
    .test(
      'sum-of-weights',
      'Sum of trait weights must add up to 1!',
      softSkillTraits => {
        if (!softSkillTraits?.length) return true;
        const sum =
          softSkillTraits?.reduce(
            (acc, curr) => acc + (curr?.weight ?? 0),
            0
          ) ?? 0;
        return Number(sum.toFixed(4)) === 1;
      }
    ),
  focusedSoftSkills: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string().required('Must not leave relation empty!'),
      })
    )
    .required(),
  broadSoftSkills: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string().required('Must not leave relation empty!'),
      })
    )
    .required('Must not leave relation empty'),
  category: yup.object().shape({
    label: yup.string().required(),
    value: yup
      .mixed<SoftSkillCategory>()
      .oneOf(Object.values(SoftSkillCategory))
      .required(),
  }),
});

export const getEditSoftSkillInitialValues = (
  softSkill: SoftSkillFragment | undefined
): TEditSoftSkillFormValues => {
  return {
    _id: softSkill?.id ?? -1,
    subId: softSkill?.subId ?? '',
    name: softSkill?.name ?? '',
    owner: {
      label: softSkill?.company?.name ?? 'Global',
      value: softSkill?.company?.id ?? null,
    },
    taxonomyVersion: softSkill?.taxonomyVersion ?? 1,
    description: softSkill?.description ?? '',
    category: {
      label: formatCategory(
        softSkill?.category ?? SoftSkillCategory.RightForTheRole
      ),
      value: softSkill?.category ?? SoftSkillCategory.RightForTheRole,
    },
    granularity: isBroad(softSkill)
      ? formatGranularity(SoftSkillGranularity.Broad)
      : formatGranularity(SoftSkillGranularity.Focused),
    focusedSoftSkills: isBroad(softSkill)
      ? softSkill.focusedSoftSkills.map(({ name, subId }) => ({
          label: name,
          value: subId,
        }))
      : [],
    broadSoftSkills:
      softSkill?.__typename === 'FocusedSoftSkill'
        ? softSkill.broadSoftSkills.map(({ name, subId }) => ({
            label: name,
            value: subId,
          }))
        : [],
    softSkillTraits:
      softSkill?.softSkillTraits?.map(({ trait, orientation, weight }) => ({
        trait: { label: trait?.name ?? '', value: trait?.id ?? -1 },
        weight,
        orientation: {
          label: formatOrientation(orientation),
          value: orientation,
        },
      })) ?? [],
  };
};

export type TAddSoftSkillFormValues = {
  name?: string;
  description?: string;
  owner: TRequiredSelectOption<number | null>;
  category?: TRequiredSelectOption<SoftSkillCategory>;
  granularity?: TRequiredSelectOption<SoftSkillGranularity>;
  taxonomyVersion: number;
};

export const addSoftSkillValidationSchema = yup.object().shape({
  name: yup
    .string()
    .ensure()
    .min(3, "Soft skill name can't be shorter than 3 characters!")
    .max(40, "Soft skill name can't be longer than 40 characters!")
    .required('Soft skill name is required')
    .test(
      'name-sentence-case',
      'Name must be sentence case!',
      softSkillName => {
        const head = softSkillName.charAt(0);
        const tail = softSkillName.slice(1);
        return (
          head === head.toLocaleUpperCase() && tail === tail.toLocaleLowerCase()
        );
      }
    )
    .test(
      'name-whitespace',
      'Name must not have leading or trailing whitespace!',
      softSkillName => {
        return !softSkillName.startsWith(' ') && !softSkillName.endsWith(' ');
      }
    ),
  description: yup
    .string()
    .min(10, "Description can't be shorter than 10 characters!")
    .max(200, "Description can't be longer than 200 characters!")
    .test(
      'description-sentence-case',
      'Description must be sentence case!',
      description => {
        if (!description) return true;
        const head = description.charAt(0);
        const tail = description.slice(1);
        return (
          head === head.toLocaleUpperCase() && tail === tail.toLocaleLowerCase()
        );
      }
    )
    .test(
      'description-whitespace',
      'Description must not have leading or trailing whitespace!',
      description => {
        if (!description) return true;
        return !description.startsWith(' ') && !description.endsWith(' ');
      }
    ),
  taxonomyVersion: yup
    .number()
    .integer()
    .min(1, 'Minimum version number is 1!')
    .max(2, 'Maximum version number is 2!'),
  category: yup.object().shape({
    label: yup.string(),
    value: yup
      .mixed<SoftSkillCategory>()
      .oneOf(Object.values(SoftSkillCategory))
      .required('You must select a category!'),
  }),
  granularity: yup.object().shape({
    label: yup.string(),
    value: yup
      .mixed<SoftSkillGranularity>()
      .oneOf(Object.values(SoftSkillGranularity))
      .required('You must select a granularity!'),
  }),
});

export const getAddSoftSkillInitialValues = (): TAddSoftSkillFormValues => {
  return {
    name: undefined,
    description: undefined,
    owner: {
      label: 'Global',
      value: -1,
    },
    taxonomyVersion: 2,
    category: undefined,
    granularity: undefined,
  };
};
