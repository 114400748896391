import { InfoItem } from 'components/molecules/InfoItem/InfoItem';
import { Modal } from '@spotted-zebra-uk/ui-components';
import styles from '../AtsLinks.module.scss';

type UnlinkingModalTypes = {
  companyId: number;
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  selectedCompanyName: string;
  selectedJobsCount: number;
  loadingLinking: boolean;
};

export function UnlinkingModal({
  isOpen,
  onConfirm,
  onClose,
  selectedCompanyName,
  selectedJobsCount,
  loadingLinking,
}: UnlinkingModalTypes) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Unlink requisitions from project(s)"
      buttonsProps={{
        onConfirmButtonClick: onConfirm,
        onCancelButtonClick: onClose,
        confirmButtonChildren: 'Unlink',
        cancelButtonChildren: 'Cancel',
        confirmButtonProps: {
          loading: loadingLinking,
          className: styles.negativeBtn,
        },
      }}
    >
      <section className={styles.modalContent}>
        <InfoItem label="Company" value={selectedCompanyName} />
        <InfoItem label="Requsitions selected" value={selectedJobsCount} />
        <p className={styles.modalParagraph}>
          The selected requisition(s) will lose their project link, affecting
          data exports and future integrations.
        </p>
      </section>
    </Modal>
  );
}
