import { FormType } from 'enums/forms/form';
import { ModalTypes } from 'enums/modalTypes';
import {
  IgInterviewGuideCategory,
  IgStep,
  Trait,
  User,
} from 'generated/graphql';
import { ReactNode } from 'react';
import { AddProjectRequestDocumentModalProps } from 'views/ModalView/AddProjectRequestDocumentModal';
import { ICompanyEmployeeModal } from 'views/ModalView/CompanyEmployeeModal';
import { IEditHRManagerEmployeeIDModal } from 'views/ModalView/EditHRManagerEmployeeIDModal';
import { NotificationModalProps } from 'views/ModalView/NotificationModal';
import { IProjectRequestApprovalModal } from 'views/ModalView/ProjectRequestApprovalModal';
import { IProjectRequestApprovalStatusModal } from 'views/ModalView/ProjectRequestApprovalStatusModal';
import { IProjectRequestJobSpecificationModal } from 'views/ModalView/ProjectRequestJobSpecificationModal';
import { IProjectRequestManagerSurveyModal } from 'views/ModalView/ProjectRequestManagerSurveyModal';
import { IStaticContentFiltersFormValues } from 'views/ModalView/StaticContentFiltersModal/StaticContentFiltersForm/StaticContentFiltersForm';
import { IFilteredTestCandidate } from 'views/User/UserDetails/UserMainResets/UserReset/UserResets.interface';
import { IUserResponse } from 'views/User/UserDetails/UserRoles/UserRoles';
import { TFormFieldValue } from './forms/form';
import { IReportWidget, TReportCompany } from './report';
import { TAlertTypes } from './snackBar';
import { TWidgetOptions } from './widget';

export interface StyledConfirmationModalProps {
  message: string;
  onSave: () => void;
  open?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  saveBtnText?: ReactNode;
  cancelBtnText?: ReactNode;
  saveBtnColor?: 'green' | 'purple' | 'blue';
}

export type TCandidateInfoModal = {
  candidateInfoFieldId?: number;
  companyId?: number;
  formFilter?: FormType;
};

//Add softskill modal
export type TAddSoftSkillModal = {};

export type TAddTraitModal = {};

//Edit softskill modal
export type TEditTraitModal = {
  trait: Trait;
};

export interface TValidateContentModal {}

export interface TStaticContentFiltersModal {
  isSoftSkillFilterIncluded?: boolean;
  isTraitFilterIncluded?: boolean;
  isGradeFilterIncluded?: boolean;
  isRoleLevelFilterIncluded?: boolean;
  isClassificationFilterIncluded?: boolean;
  filters?: IStaticContentFiltersFormValues;
  onSubmit: (values: IStaticContentFiltersFormValues) => void;
}

//TextFormFieldModal
export type TTextFormFieldModal = {
  id: string;
  name: string;
  label: string;
  onChange: (value: TFormFieldValue) => void;
  initialValue: string;
  showActionButton?: boolean;
  onActionButtonClick?: (value: string) => void;
  actionButtonLabel?: string;
  showCancelButton?: boolean;
  cancelButtonLabel?: string;
  changeValueOnClose?: boolean;
};

export type TWarningModalProps = {
  message: string;
};

export type TGetSuccessProfileReportPdfModalProps = {
  projectId: number;
  successProfileId: number;
};

export type TReportModalProps = {
  message: string;
};

export type TAddWidgetOptionModalProps = {
  widgetId: number;
  widgetOptionItem?: TWidgetOptions;
};

export type TAddWidgetModalProps = {};
export type TAddCategoryModalProps = {};
export type TAddInterviewGuideModalProps = {
  name: string;
  ownerId: number;
};
export type TInterviewGuideCategoryModalProps = {
  interviewGuideCategory: IgInterviewGuideCategory;
};

export type TIgStepConfigModal = {
  igStep?: IgStep;
  interviewGuideId: number;
};

export type TAddNewUserRoleModalProps = {
  userInfo: User;
  accessedFromCompany: boolean;
  currentCompanyId: number | undefined;
  isSuperAdmin: boolean;
};
export type TAddNewUserModalProps = {
  companyId: string;
  isSuperAdmin: boolean;
};
export type TEditUserModalProps = {
  userInfo: IUserResponse;
  companyId: string;
};

export type TReportsModalProps = {
  id?: string;
  name: string;
  type: string;
  subType: string;
  company?: TReportCompany;
  ownerId: number;
  ownerType: string;
  copyReportId?: number;
};

export type TConfigModalProps = {
  reportConfig: IReportWidget;
  getModalWidgetInfo: (
    updatedWidgetOption: TWidgetOptions[],
    dndId?: number
  ) => void;
};

export type TRetrieveTpDetailsModalProps = {
  userInfo: IUserResponse;
  stageId: number;
  projectId: number;
};

export type TAddNewCompanySignatureModalProps = {
  companyId: number;
  displaySnackbar: (msg?: string, alertType?: TAlertTypes) => void;
  getNewDefaultId: (id: number) => void;
};

export type TStageIndirectInvitationModal = {
  stageId: number;
  projectId: number;
};

export type TMitigateCircumstanceModal = {
  testCandidate: IFilteredTestCandidate;
  isDefault: boolean;
  candidateId: number;
  getCurrentAssessmentId: (assesementId: number) => void;
};

export type TModalProps =
  | TCandidateInfoModal
  | TEditTraitModal
  | TStaticContentFiltersModal
  | TTextFormFieldModal
  | TWarningModalProps
  | TGetSuccessProfileReportPdfModalProps
  | TReportsModalProps
  | TAddWidgetOptionModalProps
  | TAddWidgetModalProps
  | TAddNewUserRoleModalProps
  | TConfigModalProps
  | TRetrieveTpDetailsModalProps
  | TAddNewCompanySignatureModalProps
  | TStageIndirectInvitationModal
  | TMitigateCircumstanceModal
  | StyledConfirmationModalProps
  | NotificationModalProps
  | AddProjectRequestDocumentModalProps
  | IProjectRequestJobSpecificationModal
  | IProjectRequestManagerSurveyModal
  | IProjectRequestApprovalModal
  | IProjectRequestApprovalStatusModal
  | ICompanyEmployeeModal
  | IEditHRManagerEmployeeIDModal
  | NotificationModalProps;

export type TModal = {
  modalType: ModalTypes;
  modalProps: TModalProps;
};

export interface IGetModalsQueryResponse {
  modals: TModal[];
}

export enum IconType {
  INFORMATION = 'INFORMATION',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}
