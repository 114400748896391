import clsx from 'clsx';
import { useState } from 'react';
import { RadioButton } from '@spotted-zebra-uk/ui-components';
import styles from './UpdateCognitiveTestsBlocked.module.scss';

const dataTestId = 'update-cognitive-tests-blocked__radio-button';

export enum CognitiveTestsBlockedValues {
  BLOCKED = 'Disabled',
  NOT_BLOCKED = 'Enabled',
}

const UpdateCognitiveTestsBlocked = ({
  areCognitiveTestsBlocked,
  onSubmit,
  disabled = false,
}: {
  areCognitiveTestsBlocked: boolean;
  onSubmit: Function;
  handleModal?: (handleChange: (value: boolean) => void) => void;
  disabled?: boolean;
}) => {
  const [cognitiveTestsBlocked, setCognitiveTestsBlocked] = useState(
    areCognitiveTestsBlocked
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === CognitiveTestsBlockedValues.BLOCKED;
    onSubmit(() => setCognitiveTestsBlocked(newValue));
  };

  return (
    <div className={styles.container} data-testid={`${dataTestId}-container`}>
      <div className={clsx(styles.label, disabled && styles.disabledLabel)}>
        Enable/disable cognitive tests
      </div>
      <form className={styles.radioGroup}>
        <UpdateCognitiveTestsRadioButton
          cognitiveTestsBlocked={CognitiveTestsBlockedValues.NOT_BLOCKED}
          checked={!cognitiveTestsBlocked}
          handleChange={handleChange}
          disabled={disabled}
        />
        <UpdateCognitiveTestsRadioButton
          cognitiveTestsBlocked={CognitiveTestsBlockedValues.BLOCKED}
          checked={cognitiveTestsBlocked}
          handleChange={handleChange}
          disabled={disabled}
        />
      </form>
    </div>
  );
};

export default UpdateCognitiveTestsBlocked;

const UpdateCognitiveTestsRadioButton = ({
  cognitiveTestsBlocked,
  checked,
  handleChange,
  disabled,
}: {
  cognitiveTestsBlocked: CognitiveTestsBlockedValues;
  checked: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}) => {
  return (
    <RadioButton
      name={cognitiveTestsBlocked}
      id={cognitiveTestsBlocked}
      checked={checked}
      data-testid={`${dataTestId}-${cognitiveTestsBlocked}`}
      onChange={handleChange}
      value={cognitiveTestsBlocked}
      disabled={disabled}
    >
      {cognitiveTestsBlocked}
    </RadioButton>
  );
};
