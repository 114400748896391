import { TJobRoleFormValues } from 'components/feature/jobRole/JobRoleForm/JobRoleForm.types';
import { orientationOptions } from 'components/feature/jobRole/TraitsFormItem/TraitsFormItem';
import Decimal from 'decimal.js';
import { useField, useFormikContext } from 'formik';
import {
  Orientation,
  SoftSkillFragment,
  useSoftSkillFindManyPaginatedQuery,
} from 'generated/graphql';
import { debounce } from 'lodash';
import uniqBy from 'lodash/uniqBy';
import { FC, useMemo, useState } from 'react';
import {
  IFormikSelectFormField,
  SelectFormField,
  TNotification,
  TSelectOption,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

export interface IFormikSoftSkillNameSelectFormField
  extends Omit<IFormikSelectFormField, 'options'> {
  companyId: string;
  predefinedSoftSkills?: SoftSkillFragment[];
  index: number;
}

type PaginationStateType = Record<
  string,
  { currentPage?: number; totalPages?: number }
>;

export const PAGE_SIZE = 20;
const SEARCH_DEBOUNCE_TIME = 500;

export const FormikSoftSkillNameSelectFormField: FC<IFormikSoftSkillNameSelectFormField> =
  ({ companyId, id, predefinedSoftSkills, index, ...rest }) => {
    const { handleMsgType } = useNotification();
    const companyIdNumber = Number(companyId);

    const [isLoading, setIsLoading] = useState(false);
    const [softSkills, setSoftSkills] = useState<SoftSkillFragment[]>(
      predefinedSoftSkills ? [...predefinedSoftSkills] : []
    );
    const [pagination, setPagination] = useState<PaginationStateType>({
      '': {
        currentPage: 1,
        totalPages: undefined,
      },
    });
    const [searchValue, setSearchValue] = useState('');

    const [field, meta] = useField<TSelectOption>(id);
    const { values: formValues, setFieldValue } =
      useFormikContext<TJobRoleFormValues>();

    const handleChange = (newValues: TSelectOption) => {
      if (newValues.value === field.value.value) return;

      const selectedSoftSkill = softSkills.find(
        ss => ss.id === Number(newValues.value)
      );

      if (!selectedSoftSkill) return;

      // After selecting the soft skill, we update other fields in the row.
      // Soft skill type, weight and traits values are updated.
      const softSkillRowValues = formValues.softSkills[index];

      const newSoftSkillRowTraitsValues =
        selectedSoftSkill.softSkillTraits?.map(ssTrait => ({
          id: ssTrait.id || 0,
          traitId: ssTrait.traitId || 0,
          name: ssTrait.traitName || '',
          weight: new Decimal(ssTrait.weight).mul(100),
          orientation:
            orientationOptions[
              ssTrait.orientation === Orientation.Standard ? '1' : '0'
            ],
        })) || [];

      const newSoftSkillRowValues = {
        softSkillId: newValues,
        name: selectedSoftSkill.name,
        type: {
          value: softSkillRowValues.type.value,
          label: softSkillRowValues.type.label,
        },
        weight: '',
        traitsValues: newSoftSkillRowTraitsValues,
        includeInCalibration: softSkillRowValues.includeInCalibration,
      };

      setFieldValue(`softSkills.${index}`, newSoftSkillRowValues);
    };

    const hasError = Boolean(meta.error && meta.touched);
    const error = meta.error as unknown as
      | { label: string; value: string }
      | undefined;

    useSoftSkillFindManyPaginatedQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        filters: {
          companyId: companyIdNumber,
          name: searchValue,
        },
        pagination: {
          page: pagination[searchValue]?.currentPage
            ? pagination[searchValue].currentPage
            : 1,
          size: PAGE_SIZE,
        },
      },
      onCompleted: data => {
        const resultSkills = data?.SoftSkillFindManyPaginated.data || [];
        // Takes care that skills that are added previously to skills profile are in the options.
        // Without this, some skills would not be visible as selected.
        const parsedPredefinedSoftSkills = predefinedSoftSkills || [];
        const newSoftSkills = uniqBy(
          [...parsedPredefinedSoftSkills, ...resultSkills, ...softSkills],
          'id'
        );

        const pageInfo = data?.SoftSkillFindManyPaginated.pageInfo;

        setPagination(prev => ({
          ...prev,
          [searchValue]: {
            currentPage: pageInfo?.currentPage,
            totalPages: pageInfo?.pageTotal,
          },
        }));
        setSoftSkills(newSoftSkills);
        setIsLoading(false);
      },
      onError: error => {
        handleMsgType({ type: TNotification.error, message: error?.message });
        setIsLoading(false);
      },
    });

    const searchSoftSkills = async (inputValue: string) => {
      if (inputValue === searchValue || !inputValue) return;
      setIsLoading(true);
      setSearchValue(inputValue);
    };

    const debouncedSearchSoftSkills = debounce(
      searchSoftSkills,
      SEARCH_DEBOUNCE_TIME
    );

    const handleLoadMore = async () => {
      setIsLoading(true);
      const currentPage = pagination[searchValue]?.currentPage;
      const newPage = currentPage ? currentPage + 1 : 1;
      setPagination(prev => ({
        ...prev,
        [searchValue]: {
          currentPage: newPage,
          totalPages: pagination[searchValue]?.totalPages,
        },
      }));
    };

    const options = useMemo(() => {
      if (!softSkills || isLoading) return [];

      // Parse soft skills to options.
      const parsedOptions = softSkills.map(
        ({ id, name, company, description }) => ({
          value: `${id}`,
          label: name,
          labelNote: description,
          labelDescription: company?.name
            ? `${company.name} skill`
            : 'Global skill',
        })
      );

      // Remove skills that are already selected in other rows from the options.
      const otherSelectedSoftSkillIds = formValues.softSkills
        .map(formValuesSoftSkills => formValuesSoftSkills.softSkillId.value)
        .filter(
          otherSelectedSoftSkillId =>
            otherSelectedSoftSkillId &&
            otherSelectedSoftSkillId !== field.value.value
        );

      const filteredOptions = parsedOptions.filter(option => {
        return !otherSelectedSoftSkillIds.includes(option.value);
      });

      // Sort options alphabetically.
      return filteredOptions.sort((a, b) =>
        a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
      );
    }, [softSkills, isLoading, formValues.softSkills, field.value.value]);

    const value = useMemo(
      () => options.find(option => option.value === field.value.value),
      [options, field.value.value]
    );
    const currentPage = pagination[searchValue]?.currentPage;
    const totalPages = pagination[searchValue]?.totalPages;
    const loadMore = currentPage && totalPages && currentPage < totalPages;

    return (
      <SelectFormField
        {...rest}
        id={id}
        value={value}
        onChange={handleChange}
        onInputChange={debouncedSearchSoftSkills}
        onLoadMore={handleLoadMore}
        loadMore={loadMore}
        options={options}
        hasError={hasError}
        bottomText={hasError ? error?.value : ''}
        hasClearIndicator={false}
        loadMoreButtonText="Load more results"
        isLoading={isLoading}
      />
    );
  };
