import { useUpdateCompanyMutation } from 'generated/graphql';
import { ChangeEvent, useState } from 'react';
import { RadioButton } from '@spotted-zebra-uk/ui-components';
import styles from './ToggleInterviewGuide.module.scss';

type ToggleInterviewGuideProps = {
  initialValue: boolean;
  companyId: number;
  companyName: string;
};

export function ToggleInterviewGuide({
  initialValue,
  companyId,
  companyName,
}: ToggleInterviewGuideProps) {
  const [isChecked, setIsChecked] = useState(initialValue);

  const [updateCompany] = useUpdateCompanyMutation();

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const isEnabled = e.target.value === 'Enabled';
    setIsChecked(isEnabled);

    updateCompany({
      variables: {
        name: companyName,
        id: companyId,
        isInterviewGuideEnabled: isEnabled,
      },
    });
  }

  return (
    <div className={styles.container}>
      <p>Enable/disable interview guide</p>
      <form className={styles.form}>
        <RadioButton
          name={'interviewGuideToggle'}
          id={'interviewGuideToggleEnabled'}
          value={'Enabled'}
          data-testid={`interview-guide-toggle`}
          onChange={handleChange}
          checked={isChecked}
        >
          Enabled
        </RadioButton>
        <RadioButton
          name={'interviewGuideToggle'}
          id={'interviewGuideToggleDisabled'}
          value={'Disabled'}
          data-testid={`interview-guide-toggle`}
          onChange={handleChange}
          checked={!isChecked}
        >
          Disabled
        </RadioButton>
      </form>
    </div>
  );
}
