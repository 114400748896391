import { makeStyles } from '@mui/styles';

const useEditCompanyStyle = makeStyles(() => ({
  container: {
    paddingTop: 40,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 40,
    width: '100%',
    margin: 0,
  },
  logo: {
    height: 50,
    width: 150,
  },
  companyNameWrapper: {
    height: 69,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    height: 69,
  },
  heading: {
    fontWeight: 500,
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 300,
  },
  domainsContainer: {
    minWidth: '50%',
    margin: '20px 0',
  },
  fullWidth: {
    minWith: '100%',
  },
  reportAssignment: {
    display: 'flex',
    width: '100%',
    margin: '20px 0',
  },
  notificationSection: {
    paddingBottom: '16px',
    marginBottom: '10px',
  },
  notification: {
    width: '456px !important',
  },
  reportAccess: {
    margin: '0px',
    paddingTop: '6px',
    paddingLeft: '15px',
  },
  updateCognitiveTestsBlocked: {
    margin: '0px',
    paddingTop: '30px',
    paddingLeft: '15px',
  },
}));

export default useEditCompanyStyle;
