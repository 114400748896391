import {
  SortOption,
  StackOneLocationsFindManyPaginatedQuery,
  StackOneLocationSortField,
  useStackOneLocationsFindManyPaginatedQuery,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import {
  TMultiselectOption,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

export function useLocationsSelect(companyId: number | undefined) {
  const isEnabled = companyId !== undefined;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [locationsForSelect, setLocationsForSelect] = useState<
    TMultiselectOption[]
  >([]);

  const { handleMsgType } = useNotification();

  const { loading, data } = useStackOneLocationsFindManyPaginatedQuery({
    skip: !isEnabled,
    variables: {
      companyId: companyId ?? -1,
      take: 100,
      skip: (currentPage - 1) * 100,
      search: searchValue,
      sortField: StackOneLocationSortField.Name,
      sortOrder: SortOption.Asc,
    },
    onCompleted: data => {
      const parsedData = parseDataForSelect(data);
      setLocationsForSelect(_prev => [..._prev, ...parsedData]);
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: `${error.message}`,
      });
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setLocationsForSelect([]);
    setCurrentPage(1);
  }, [searchValue]);

  useEffect(() => {
    setCurrentPage(1);
    setSearchValue('');
    setLocationsForSelect([]);
  }, [companyId]);

  const itemsTotal =
    data?.stackOneLocationFindManyPaginated?.pageInfo?.itemsTotal || 0;

  const hasLoadMore = itemsTotal > locationsForSelect.length;

  return {
    selectOptions: locationsForSelect,
    loading,
    hasMoreItems: hasLoadMore,
    loadMore: () => setCurrentPage(_prev => _prev + 1),
    searchValue,
    setSearchValue,
  };
}

function parseDataForSelect(
  data: StackOneLocationsFindManyPaginatedQuery | undefined
): TMultiselectOption[] {
  if (!data || !data.stackOneLocationFindManyPaginated.data) {
    return [];
  }

  return data.stackOneLocationFindManyPaginated.data.map(location => ({
    value: String(location.id),
    label: `${location.name}`,
    labelNote: `${location.id}`,
  }));
}
