import Icon from 'components/atoms/Icon';
import { ItemsCount } from 'components/ui/ItemsCount/ItemsCount';
import { projectsRoutes } from 'constants/navigation';
import {
  ProjectOrderField,
  ProjectTriboolFilter,
  SortOption,
  useProjectsFindManyPaginatedQuery,
} from 'generated/graphql';
import { FC, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  HeaderSortDirection,
  Search,
  Table,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import NoSearchResultsTableContent from './components/NoSearchResultsTableContent/NoSearchResultsTableContent';
import ProjectsFilterModal, {
  emptyProjectFilterFormValues,
} from './components/ProjectsFilterModal/ProjectsFilterModal';
import {
  DEFAULT_TABLE_PAGE_SIZE,
  getFiltersButtonAriaLabel,
  getFiltersCount,
  parseTableData,
  tableHeaders,
} from './Projects.helpers';
import styles from './Projects.module.scss';

type Pagination = {
  page: number;
  pageSize: number;
};

type TOrder =
  | {
      field: ProjectOrderField;
      direction: SortOption;
    }
  | undefined;

const Projects: FC = () => {
  const { handleMsgType } = useNotification();
  const navigate = useNavigate();

  // filters
  const [filters, setFilters] = useState<typeof emptyProjectFilterFormValues>(
    emptyProjectFilterFormValues
  );
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

  // search
  const [searchValue, setSearchValue] = useState('');
  const [order, setOrder] = useState<TOrder>({
    direction: SortOption.Desc,
    field: ProjectOrderField.ProjectCreatedAt,
  });

  // pagination
  const [currentTablePage, setCurrentTablePage] = useState<number>(1);
  const [currentTablePageSize, setCurrentPageSize] = useState<number>(
    DEFAULT_TABLE_PAGE_SIZE
  );

  const { data, loading } = useProjectsFindManyPaginatedQuery({
    // TODO: Remove after implementing proper project caching for
    // project create, project delete and project edit.
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        textSearch: searchValue,
        withDeleted: filters.isArchived
          ? ProjectTriboolFilter.All
          : ProjectTriboolFilter.OnlyNot,
      },
      order: order,
      pagination: {
        page: currentTablePage,
        size: currentTablePageSize,
      },
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error.message || 'Error occurred while getting projects!',
      });
    },
  });

  const tableRows = useMemo(
    () => data?.Projects.data?.map(parseTableData),
    [data?.Projects.data]
  );

  const handleSearch = (value: string) => {
    // Reset pagination on search change.
    setCurrentTablePage(1);
    setSearchValue(value);
  };

  const handleSearchClearBtnClick = () => {
    // Reset pagination on search change.
    setCurrentTablePage(1);
    handleSearch('');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRowClick = (rowValue: any) => {
    const projectUrl = projectsRoutes.editProjectView.url(rowValue.id);
    navigate(projectUrl);
  };

  const handleRowAltClick = (rowValue: { id: number }) => {
    const projectUrl = projectsRoutes.editProjectView.url(rowValue.id);
    window.open(projectUrl, '_blank');
  };

  const handlePaginationChange = ({
    page: newPage,
    pageSize: newPageSize,
  }: Pagination) => {
    currentTablePage !== newPage && setCurrentTablePage(newPage);
    currentTablePageSize !== newPageSize && setCurrentPageSize(newPageSize);
  };

  const handleSubmitFilters = (
    formValues: typeof emptyProjectFilterFormValues
  ) => {
    setFilters(formValues);
  };

  const handleSortChange = (
    field: string,
    tableSortDirection: HeaderSortDirection
  ) => {
    let direction;

    if (tableSortDirection === HeaderSortDirection.ASC) {
      direction = SortOption.Asc;
    }

    if (tableSortDirection === HeaderSortDirection.DESC) {
      direction = SortOption.Desc;
    }

    const newSortOrder = direction && {
      field: field as ProjectOrderField,
      direction,
    };

    setOrder(newSortOrder);
  };

  const handleTableSort = useMemo(() => {
    let sortDirection = HeaderSortDirection.NONE;

    if (order?.direction === SortOption.Asc) {
      sortDirection = HeaderSortDirection.ASC;
    }

    if (order?.direction === SortOption.Desc) {
      sortDirection = HeaderSortDirection.DESC;
    }

    return {
      sortDirection,
      onClick: handleSortChange,
      sortedHeader: order?.field || '',
    };
  }, [order?.direction, order?.field]);

  const filterCount = useMemo(() => {
    return getFiltersCount(filters) || undefined;
  }, [filters]);
  const filtersButtonAriaLabel = getFiltersButtonAriaLabel(filters);

  const itemsTotal = data?.Projects.pageInfo?.itemsTotal;
  const createProjectUrl = projectsRoutes.createProject.url();

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Projects</h1>
      <Table
        className={styles.table}
        headerData={tableHeaders}
        rowData={tableRows}
        onRowClick={handleRowClick}
        onRowCtrlOrCmdClick={handleRowAltClick}
        loading={loading}
        hasPagination
        unsortableHeaderKeys={['archived']}
        tableSearchDirty={Boolean(searchValue)}
        externalSort={handleTableSort}
        noSearchResultsText={<NoSearchResultsTableContent />}
        pagination={{
          currentPageSize: currentTablePageSize,
          onChange: handlePaginationChange,
          totalNumberOfItems: itemsTotal || 0,
          page: currentTablePage,
        }}
        hasToolbar
        toolbarConf={{
          componentListLeftStart: (
            <ItemsCount
              count={itemsTotal || 0}
              ariaLabelSuffix="projects in the table"
            />
          ),
          componentListLeft: (
            <div className={styles.toolbarContainer}>
              <form
                onSubmit={e => e.preventDefault()}
                className={styles.searchForm}
              >
                <Search
                  internalSearch={false}
                  onSearch={handleSearch}
                  inputValue={searchValue}
                  onClearBtnClick={handleSearchClearBtnClick}
                  ariaLabel="Search"
                  ariaDisabled={false}
                />
              </form>
              <Button
                variant="secondary"
                leftIcon={<Icon icon="filter" />}
                onClick={() => setIsFiltersModalOpen(true)}
                counter={filterCount}
                aria-label={filtersButtonAriaLabel}
                data-testid="projects-page-filter-button"
              >
                Filter
              </Button>
            </div>
          ),
          componentListRight: (
            <Link to={createProjectUrl}>
              <Button>Add project</Button>
            </Link>
          ),
        }}
      />
      <ProjectsFilterModal
        isOpen={isFiltersModalOpen}
        onClose={() => setIsFiltersModalOpen(false)}
        onSubmit={handleSubmitFilters}
        initialValues={filters}
      />
    </div>
  );
};

export default Projects;
