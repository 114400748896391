import {
  SoftSkillFragment,
  useSoftSkillFindManyQuery,
  useTraitsQuery,
} from 'generated/graphql';
import { FC } from 'react';
import BehaviourSoftSkillTable from './BehaviourSoftSkillTable/BehaviourSoftSkillTable';
import BehaviourTraitTable from './BehaviourTraitTable/BehaviourTraitTable';
import styles from './BehavioutContentTable.module.scss';

interface IBehaviourContentTable {
  softSkillIds?: number[];
  traitIds?: number[];
}
const BehaviourContentTable: FC<IBehaviourContentTable> = ({
  traitIds,
  softSkillIds,
}) => {
  const removeDuplicates = (softSkillIds?: number[]): number[] | undefined => {
    return softSkillIds?.filter((item, pos) => {
      return softSkillIds?.indexOf(item) === pos;
    });
  };
  const softSkillsFindManyQueryResult = useSoftSkillFindManyQuery({
    variables: { ids: removeDuplicates(softSkillIds) },
    skip: !Boolean(softSkillIds),
  });
  const traitsFindManyQueryResult = useTraitsQuery({
    variables: { isArchived: false, ids: removeDuplicates(traitIds) },
    skip: !Boolean(traitIds),
  });

  const softSkillTableRows = (
    softSkillsFindManyQueryResult.data?.SoftSkillFindMany as SoftSkillFragment[]
  )
    ?.filter(softSkill => !softSkill.deletedAt)
    .map(ss => ({
      id: ss.id.toString(),
      name: ss.name,
      companyOwner: ss.company?.name || '-',
    }));
  const traitTableRows = traitsFindManyQueryResult.data?.Traits?.map(trait => ({
    id: trait.id.toString(),
    name: trait.name,
  }));

  return (
    <div className={styles.container}>
      {softSkillTableRows && softSkillTableRows?.length && (
        <BehaviourSoftSkillTable softSkills={softSkillTableRows} />
      )}
      {traitTableRows && traitTableRows?.length && (
        <BehaviourTraitTable traits={traitTableRows} />
      )}
    </div>
  );
};

export default BehaviourContentTable;
