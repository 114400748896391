export const appRoutes = {
  projects: {
    path: '/projects',
    url: () => '/projects',
  },
  jobRoles: {
    path: '/job-roles',
    url: () => '/job-roles',
  },
  companies: {
    path: '/companies',
    url: () => '/companies',
  },
  projectRequests: {
    path: '/project-requests',
    url: () => '/project-requests',
  },
  results: {
    path: '/results',
    url: () => '/results',
  },
  formManager: {
    path: '/form-manager',
    url: () => '/form-manager',
  },
  stageActions: {
    path: '/stage-actions',
    url: () => '/stage-actions',
  },
  content: {
    path: '/content',
    url: () => '/content',
  },
  reports: {
    path: '/reports',
    url: () => '/reports',
  },
  users: {
    path: '/users',
    url: () => '/users',
  },
  apiKeys: {
    path: '/api-keys',
    url: () => '/api-keys',
  },
  interviewGuides: {
    path: '/interview-guides',
    url: () => '/interview-guides',
  },
  atsLinks: {
    path: '/ats-links',
    url: () => '/ats-link',
  },
};

export const stageActionsRoutes = {
  codingAssessments: {
    path: `${appRoutes.stageActions.path}/coding-assessments`,
    url: () => `${appRoutes.projects.path}/coding-assessments`,
  },
};

export const projectsRoutes = {
  createProject: {
    path: `${appRoutes.projects.path}/new-project`,
    url: () => `${appRoutes.projects.path}/new-project`,
  },
  createProjectWithProjectRequest: {
    path: `${appRoutes.projects.path}/new-project/:projectRequestId`,
    url: (projectRequestId: number) =>
      `${appRoutes.projects.path}/new-project/${projectRequestId}`,
  },
  editProjectView: {
    path: `${appRoutes.projects.path}/:projectId`,
    url: (projectId: number) => `${appRoutes.projects.path}/${projectId}`,
  },
  editProjectViewWithProjectRequest: {
    path: `${appRoutes.projects.path}/:projectId/:projectRequestId`,
    url: (projectId: number, projectRequestId: number | string) =>
      `${appRoutes.projects.path}/${projectId}/${projectRequestId}`,
  },
};

export const companyRoutes = {
  createCompany: {
    path: `${appRoutes.companies.path}/new-company`,
    url: () => `${appRoutes.companies.path}/new-company`,
  },
  editCompany: {
    path: `${appRoutes.companies.path}/:companyId`,
    url: (companyId: number) => `${appRoutes.companies.path}/${companyId}`,
  },
};

export const editCompanyRoutes = {
  editCompanyApiKeys: {
    path: `${appRoutes.companies.path}/:companyId/api-keys`,
    url: (companyId: number) =>
      `${appRoutes.companies.path}/${companyId}/api-keys`,
  },
  editCompanyRoles: {
    path: `${appRoutes.companies.path}/:companyId/company-roles`,
    url: (companyId: number) =>
      `${appRoutes.companies.path}/${companyId}/company-roles`,
  },
};

export const formManagerRoutes = {
  fields: {
    path: `${appRoutes.formManager.path}/fields`,
    url: () => `${appRoutes.formManager.path}/fields`,
  },
  companyForms: {
    path: `${appRoutes.formManager.path}/company-forms`,
    url: () => `${appRoutes.formManager.path}/company-forms`,
  },
  downloadSubmittedForms: {
    path: `${appRoutes.formManager.path}/download-submitted-forms`,
    url: () => `${appRoutes.formManager.path}/download-submitted-forms`,
  },
};

export const contentRoutes = {
  softSkills: {
    path: `${appRoutes.content.path}/soft-skills`,
    url: () => `${appRoutes.content.path}/soft-skills`,
  },
  traits: {
    path: `${appRoutes.content.path}/traits`,
    url: () => `${appRoutes.content.path}/traits`,
  },
  softSkillContent: {
    path: `${appRoutes.content.path}/soft-skill-content`,
    url: () => `${appRoutes.content.path}/soft-skill-content`,
  },
  softSkillSuccessProfileContent: {
    path: `${appRoutes.content.path}/success-profile-content`,
    url: () => `${appRoutes.content.path}/success-profile-content`,
  },
  traitContent: {
    path: `${appRoutes.content.path}/trait-content`,
    url: () => `${appRoutes.content.path}/trait-content`,
  },
  interviewQuestionContent: {
    path: `${appRoutes.content.path}/interview-question-content`,
    url: () => `${appRoutes.content.path}/interview-question-content`,
  },
  validate: {
    path: `${appRoutes.content.path}/validate`,
    url: () => `${appRoutes.content.path}/validate`,
  },
  technicalSkills: {
    path: `${appRoutes.content.path}/technical-skills`,
    url: () => `${appRoutes.content.path}/technical-skills`,
  },
  behaviourContent: {
    path: `${appRoutes.content.path}/behaviour-content`,
    url: () => `${appRoutes.content.path}/behaviour-content`,
  },
};

export const technicalSkillsRoutes = {
  addNew: {
    path: `${appRoutes.content.path}/technical-skills/add`,
    url: () => `${appRoutes.content.path}/technical-skills/add`,
  },
};

export const reportsRoutes = {
  reports: {
    path: `${appRoutes.reports.path}/report`,
    url: () => `${appRoutes.reports.path}/report`,
  },
  report: {
    path: `${appRoutes.reports.path}/report/:reportId`,
    url: (reportId: number) => `${appRoutes.reports.path}/report/${reportId}`,
  },
  widgets: {
    path: `${appRoutes.reports.path}/widgets`,
    url: () => `${appRoutes.reports.path}/widgets`,
  },
  widget: {
    path: `${appRoutes.reports.path}/widgets/:widgetId`,
    url: (widgetId: number) => `${appRoutes.reports.path}/widgets/${widgetId}`,
  },
};

export const usersRoutes = {
  user: {
    path: `${appRoutes.users.path}/:userId`,
    url: (userId: number) => `${appRoutes.users.path}/${userId}`,
  },
  retrieveTpDetails: {
    path: `${appRoutes.users.path}/retrieve-tp-details`,
    url: () => `${appRoutes.users.path}/retrieve-tp-candidates`,
  },
};

export const resultsRoutes = {
  candidateResults: {
    path: `${appRoutes.results.path}/candidate-results`,
    url: () => `${appRoutes.results.path}/candidate-results`,
  },
  candidateUsage: {
    path: `${appRoutes.results.path}/candidate-usage`,
    url: () => `${appRoutes.results.path}/candidate-usage`,
  },
  skillsValidated: {
    path: `${appRoutes.results.path}/skill-validated`,
    url: () => `${appRoutes.results.path}/skill-validated`,
  },
  users: {
    path: `${appRoutes.results.path}/users`,
    url: () => `${appRoutes.results.path}/users`,
  },
  QBRData: {
    path: `${appRoutes.results.path}/qbr-data`,
    url: () => `${appRoutes.results.path}/qbr-data`,
  },
  atsRequisitions: {
    path: `${appRoutes.results.path}/ats-requisitions`,
    url: () => `${appRoutes.results.path}/ats-requisitions`,
  },
};

export const apiKeysRoutes = {
  companies: {
    path: `${appRoutes.apiKeys.path}/companies`,
    url: () => `${appRoutes.apiKeys.path}/companies`,
  },
  users: {
    path: `${appRoutes.apiKeys.path}/users`,
    url: () => `${appRoutes.apiKeys.path}/users`,
  },
};

export const interviewGuidesRoutes = {
  categories: {
    path: `${appRoutes.interviewGuides.path}/categories`,
    url: () => `${appRoutes.interviewGuides.path}/categories`,
  },
  category: {
    path: `${appRoutes.interviewGuides.path}/categories/:categoryId`,
    url: (categoryId: number) =>
      `${appRoutes.interviewGuides.path}/categories/${categoryId}`,
  },
  interviewGuides: {
    path: `${appRoutes.interviewGuides.path}/interview-guide`,
    url: () => `${appRoutes.interviewGuides.path}/interview-guide`,
  },
  interviewGuide: {
    path: `${appRoutes.interviewGuides.path}/interview-guide/:interviewGuideId`,
    url: (interviewGuideId: number) =>
      `${appRoutes.interviewGuides.path}/interview-guide/${interviewGuideId}`,
  },
};

export const projectRequestsRoutes = {
  projectRequests: {
    path: `${appRoutes.projectRequests.path}/list`,
    url: () => `${appRoutes.projectRequests.url()}/list`,
  },
  projectRequest: {
    path: `${appRoutes.projectRequests.path}/:projectRequestId`,
    url: (projectRequestId: number | string) =>
      `${appRoutes.projectRequests.url()}/${projectRequestId}`,
  },
  createProjectRequest: {
    path: `${appRoutes.projectRequests.path}/new-project-request`,
    url: () => `${appRoutes.projectRequests.url()}/new-project-request`,
  },
};

export const jobRolesRoutes = {
  createJobRole: {
    path: `${appRoutes.jobRoles.path}/new`,
    url: () => `${appRoutes.jobRoles.url()}/new`,
  },
  editJobRole: {
    path: `${appRoutes.jobRoles.path}/:jobRoleId`,
    url: (jobRoleId: number) => `${appRoutes.jobRoles.url()}/${jobRoleId}`,
  },
};
