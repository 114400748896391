import Icon from 'components/atoms/Icon';
import { StageType } from 'views/Project/components/Stages/Stage';
import DeleteInterviewStage from 'views/Project/components/Stages/StageActionButtons/DeleteInterviewStage';
import DeleteStage from 'views/Project/components/Stages/StageActionButtons/DeleteStage';
import { Button, DropdownMenu } from '@spotted-zebra-uk/ui-components';
import styles from './StageHeader.module.scss';

type PrimaryButton =
  | {
      label: string;
      onClick: () => void;
      isLoading?: boolean;
      type?: 'button';
    }
  | {
      label: string;
      onClick: (type: StageType) => void;
      disabled: boolean;
      isLoading?: boolean;
      type: 'dropdown';
      disabledDropdown?: StageType;
    };

const StageHeader = ({
  title,
  primaryButton,
  secondaryButton,
  deleteInterviewStage,
  deleteButton,
}: {
  title: string;
  primaryButton?: PrimaryButton;
  secondaryButton?: { label: string; onClick: () => void };
  deleteButton?: { projectId: number; stageId: number };
  deleteInterviewStage?: { label: string; subId: string };
}) => {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <div className={styles.actions}>
          {secondaryButton && (
            <Button
              onClick={secondaryButton.onClick}
              variant="secondary"
              size="medium"
              data-testid="stage-header__secondary-button"
            >
              {secondaryButton.label}
            </Button>
          )}
          {deleteButton && (
            <DeleteStage
              projectId={deleteButton.projectId}
              id={deleteButton.stageId}
            />
          )}
          {deleteInterviewStage && (
            <DeleteInterviewStage subId={deleteInterviewStage.subId} />
          )}
          {primaryButton && primaryButton.type === 'dropdown' ? (
            <DropdownMenu
              renderButton={(_, __, handleTriggerDropdown) => (
                <Button
                  size="medium"
                  onClick={handleTriggerDropdown}
                  className={styles.hoverActionButton}
                  disabled={primaryButton.disabled}
                  data-testid="stage-header__insert-button"
                >
                  {primaryButton.label}
                </Button>
              )}
              options={[
                {
                  label: 'Interview stage',
                  icon: (
                    <div className={styles.hoverActionIcon}>
                      <Icon icon="groups" />
                    </div>
                  ),
                  disabled: primaryButton.disabledDropdown === 'interview',
                  onClick: () => primaryButton.onClick('interview'),
                },
                {
                  label: 'Assessment stage',
                  icon: (
                    <div className={styles.hoverActionIcon}>
                      <Icon icon="assessments" />
                    </div>
                  ),
                  disabled: primaryButton.disabledDropdown === 'assessment',
                  onClick: () => primaryButton.onClick('assessment'),
                },
              ]}
            />
          ) : (
            primaryButton && (
              <Button
                onClick={primaryButton?.onClick}
                size="medium"
                loading={primaryButton?.isLoading}
                type="submit"
                data-testid="stage-header__primary-button"
              >
                {primaryButton?.label}
              </Button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default StageHeader;
