import ReportsAccess, { ReportsAccessTypes } from 'components/feature/ReportsAccess/ReportsAccess';
import UpdateCognitiveTestsBlocked from 'components/feature/UpdateCognitiveTestsBlocked/UpdateCognitiveTestsBlocked';
import CircularLoader from 'components/molecules/CircularLoader/CircularLoader';
import CandidateReportAssignment from 'components/organisms/CandidateReportAssignment/CandidateReportAssignment';
import { ModalTypes } from 'enums/modalTypes';
import {
  RAssignedType,
  ReportSubType,
  useCompanyQuery,
} from 'generated/graphql';
import { openModal } from 'graphql/modals';
import { ICompanyPath } from 'interfaces/routes';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ToggleInterviewGuide } from 'views/Company/CompanyView/EditCompany/ToggleInterviewGuide/ToggleInterviewGuide';
import { Grid, Typography } from '@mui/material';
import {
  InlineNotification,
  NotificationType,
} from '@spotted-zebra-uk/ui-components';
import CompanyEmailDomains from './CompanyEmailDomains/CompanyEmailDomains';
import CompanyEmployees from './CompanyEmployees/CompanyEmployees';
import CompanySignature from './CompanySignature/CompanySignature';
import DeleteCompany from './DeleteCompany/DeleteCompany';
import EditCompanyName from './EditCompanyName/EditCompanyName';
import useEditCompanyStyle from './EditCompanyStyle';
import UploadCompanyLogo from './UploadCompanyLogo/UploadCompanyLogo';

const EditCompany: FC = () => {
  const classes = useEditCompanyStyle();
  const { companyId } = useParams() as ICompanyPath;
  const parsedCompanyId: number = parseInt(companyId, 10);
  // donkey - use latest cache
  const response = useCompanyQuery({ variables: { id: parsedCompanyId } });
  const company = response.data?.company;
  const hasEmailDomains = company?.allowedEmailDomains?.length;

  const handleReportAccessModal = (handleChange: Function) => {
    openModal(ModalTypes.REPORTS_ACCESS, {
      id: company?.id,
      name: company?.name,
      accessType: company?.managerReportEmailLinks
        ? ReportsAccessTypes.ATTACHMENTS
        : ReportsAccessTypes.LINKS,
      onSubmit: handleChange,
    });
  };

  const handleAreCognitiveTestsBlockedModal = (handleChange: Function) => {
    openModal(ModalTypes.UPDATE_COGNITIVE_TESTS_BLOCKED, {
      id: company?.id,
      name: company?.name,
      areCognitiveTestsBlocked: company?.areCognitiveTestsBlocked,
      onSubmit: handleChange,
    });
  };

  if (response.loading) {
    return <CircularLoader customStyle={classes.loaderWrapper} />;
  }

  if (!company) {
    return null;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.container}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        {company.logoUrl ? (
          <Grid>
            <img
              className={classes.logo}
              src={company.logoUrl}
              alt="company logo"
            />
          </Grid>
        ) : null}
        <Grid className={classes.companyNameWrapper}>
          <Typography variant="h5">{company.name}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid xs={4}>
          <EditCompanyName company={company} />
        </Grid>
        <Grid
          xs={3}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid>
            <UploadCompanyLogo companyId={parsedCompanyId} />
          </Grid>
          <Grid>
            <DeleteCompany companyId={parsedCompanyId} />
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.reportAssignment}>
        <div className={classes.fullWidth}>
          <CandidateReportAssignment
            companyId={parsedCompanyId}
            reportAssignedType={RAssignedType.Company}
            subType={ReportSubType.StageResultsCandidate}
            label="Candidate Report"
            name="candidateReport"
            checkBoxId="candidateCheckbox"
            selectFormId="candidateSelectForm"
          />
        </div>
        <div className={classes.fullWidth}>
          <CandidateReportAssignment
            companyId={parsedCompanyId}
            reportAssignedType={RAssignedType.Company}
            subType={ReportSubType.StageResultsManager}
            label="Manager Report"
            name="managerReport"
            checkBoxId="managerCheckbox"
            selectFormId="managerSelectForm"
          />
        </div>
      </div>
      <div className={classes.reportAccess}>
        <ReportsAccess
          linksEnabled={company?.managerReportEmailLinks}
          onSubmit={handleReportAccessModal}
          hasModal={true}
        />
      </div>
      <div className={classes.updateCognitiveTestsBlocked}>
        <UpdateCognitiveTestsBlocked
          areCognitiveTestsBlocked={company?.areCognitiveTestsBlocked}
          onSubmit={handleAreCognitiveTestsBlockedModal}
        />
      </div>

      <div>
        <ToggleInterviewGuide
          companyId={parsedCompanyId}
          companyName={company?.name}
          initialValue={Boolean(company?.isInterviewGuideEnabled)}
        />
      </div>
      <div className={classes.domainsContainer}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.title}
        >
          <Typography variant="h5" className={classes.heading}>
            Email domains
          </Typography>
        </Grid>
        <section className={classes.notificationSection}>
          {hasEmailDomains ? (
            <InlineNotification
              notificationType={NotificationType.NEUTRAL}
              className={classes.notification}
            >
              <p>
                Only users with the following email domains can be invited to
                the company's space via the company app.
              </p>
            </InlineNotification>
          ) : (
            <InlineNotification
              title="Missing information"
              notificationType={NotificationType.WARNING}
              className={classes.notification}
            >
              <p>
                Add permitted email domains to enable company users to invite
                new members to the company's space via the company app.
              </p>
            </InlineNotification>
          )}
        </section>
        <CompanyEmailDomains
          companyId={parsedCompanyId}
          allowedEmailDomains={company.allowedEmailDomains || []}
        />
      </div>
      <div>
        <CompanyEmployees companyId={parsedCompanyId} />
        <CompanySignature companyId={parsedCompanyId} />
      </div>
    </Grid>
  );
};

export default EditCompany;
