import { Formik } from 'formik';
import {
  ActorRole,
  StageType,
  useCreateStageMutation,
  useGetProjectByIdQuery,
  useStageEditDetailsMutation,
} from 'generated/graphql';
import { FC } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { getSendEmailSettings, parseActorRolesForSelect } from '../helpers';
import StageHeader from '../StageHeader/StageHeader';
import {
  convertToUTC,
  createValidationSchema,
  updateCacheHelper,
} from './StageForm.helper';
import { IStageForm, IStageFormValues } from './StageForm.interfaces';
import styles from './StageForm.module.scss';
import StageFormFields from './StageFormFields';

const StageForm: FC<IStageForm> = ({
  onSubmit,
  onCancel,
  projectId,
  reportsAccess,
  isMultiMatch,
  initialValues,
  stageId,
}) => {
  const { handleMsgType } = useNotification();
  const { data: projectData } = useGetProjectByIdQuery({
    variables: {
      id: projectId,
    },
  });
  const productSolution = projectData?.project?.productSolution;

  const projectRoles = productSolution
    ? parseActorRolesForSelect(productSolution)
    : [];

  const validationSchema = createValidationSchema(projectRoles.length);

  const [createStage, { loading: createLoading }] = useCreateStageMutation({
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        title: 'Successfully created assessment stage',
      });
      onSubmit();
    },
    update(cache, { data }) {
      if (data) {
        updateCacheHelper(cache, data.Stage, projectId);
      }
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message:
          error?.message === 'Cannot find manyProjectJobRole!'
            ? 'Please add a job role first.'
            : error.message,
      });
    },
  });

  const [editStage, { loading: editLoading }] = useStageEditDetailsMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        title: 'Successfully updated assessment stage',
      });
      onSubmit();
    },
  });

  const handleSaveForm = (formValues: IStageFormValues) => {
    const { emailManagerReport, emailProjectTeam } = getSendEmailSettings(
      formValues.stageMode.value
    );

    const emailProjectTeamRoles: ActorRole[] = formValues.stageProjectRoles.map(
      projectRole => projectRole.value!
    );

    const payload = {
      name: formValues.stageName,
      type: formValues.stageType.value as StageType,
      startTime: convertToUTC(formValues.stageStartTime),
      endTime:
        !formValues.stageIsEndTimeNull && formValues.stageEndTime
          ? convertToUTC(formValues.stageEndTime, true)
          : null,
      renderCandidateReport: false,
      emailCandidateReport: formValues.stageEmailCandidateReport,
      emailManagerReport,
      emailProjectTeam,
      emailProjectTeamRoles,
      emailSzAdmin: formValues.stageEmailSzAdmin,
      hasCalibration: false,
      createInterviewGuide: formValues.createInterviewGuide,
      managerReportEmailLinks: formValues.managerReportEmailLinks,
      enableF2fInterviews: formValues.enableF2fInterviews,
    };

    if (stageId) {
      editStage({
        variables: {
          id: stageId,
          ...payload,
        },
      });
      return;
    }

    createStage({
      variables: {
        projectId,
        ...payload,
      },
    });
  };

  return (
    <Formik<IStageFormValues>
      initialValues={{
        ...initialValues,
        // managerReportEmailLinks initial value will be overwritten by the default set at the company level
        managerReportEmailLinks: reportsAccess || isMultiMatch,
      }}
      onSubmit={values => handleSaveForm(values)}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => {
        return (
          <div className={styles.container}>
            <StageHeader
              title="Assessment stage"
              primaryButton={{
                label: createLoading || editLoading ? 'Saving...' : 'Save',
                isLoading: createLoading || editLoading,
                onClick: () => handleSubmit(),
                type: 'button',
              }}
              secondaryButton={{
                label: 'Cancel',
                onClick: onCancel,
              }}
            />
            <StageFormFields
              projectRoles={projectRoles}
              isMultiMatch={isMultiMatch}
              reportsAccess={reportsAccess}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default StageForm;
