import { useGetProjectByIdQuery } from 'generated/graphql';
import { FC } from 'react';
import { Loader, Modal, ModalSize } from '@spotted-zebra-uk/ui-components';
import InviteNewCandidate from './InviteNewCandidate';

interface IDirectInvitationModal {
  stageId: number;
  projectId: number;
  onClose: () => void;
}

const DirectInvitationModal: FC<IDirectInvitationModal> = ({
  stageId,
  projectId,
  onClose,
}) => {
  const getProjectByIdQueryResponse = useGetProjectByIdQuery({
    variables: {
      id: projectId,
    },
  });

  return (
    <Modal
      isOpen
      header="Direct invitation"
      className="direct-invitation-modal"
      modalSize={ModalSize.SMALL}
      onClose={onClose}
    >
      {getProjectByIdQueryResponse.data?.project?.company ? (
        <InviteNewCandidate
          stageId={stageId}
          type={getProjectByIdQueryResponse.data.project?.moduleType}
          projectId={projectId}
          closeModal={onClose}
        />
      ) : (
        <Loader variant="bubbles" />
      )}
    </Modal>
  );
};

export default DirectInvitationModal;
