import { kebabCase } from 'lodash';
import React from 'react';
import styles from './InfoItem.module.scss';

const InfoItem = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) => {
  const dataTestId = `project-info-item__${kebabCase(label)}`;
  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value} data-testid={dataTestId}>
        {value}
      </div>
    </div>
  );
};

export { InfoItem };
