import { FormikContextType, useField, useFormikContext } from 'formik';
import {
  SoftSkillFragment,
  useSoftSkillRestoreMutation,
  useSoftSkillSoftDeleteMutation,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import { EditSoftSkillTraits } from 'views/StaticContent/SoftSkills/drawers/children/EditSoftSkillTraits';
import { SoftSkillRelationsForm } from 'views/StaticContent/SoftSkills/drawers/children/SoftSkillRelationsForm';
import {
  softSkillCategoryOptions,
  TEditSoftSkillFormValues,
} from 'views/StaticContent/SoftSkills/drawers/SoftSkillDrawer.helpers';
import { isBroad } from 'views/StaticContent/SoftSkills/SoftSkills.helpers';
import {
  Button,
  FormikSelectFormField,
  FormikTextInputField,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './EditSoftSkillDrawerContents.module.scss';

type TEditSoftSkillDrawerContentsArgs = {
  isEditMode: boolean;
  setIsEditMode: (b: boolean) => void;
  softSkill: SoftSkillFragment | undefined;
};

export const EditSoftSkillDrawerContents = ({
  isEditMode,
  setIsEditMode,
  softSkill,
}: TEditSoftSkillDrawerContentsArgs) => {
  const onSave = (formProps: FormikContextType<TEditSoftSkillFormValues>) => {
    formProps.validateForm(formProps.values);
    if (formProps.isValid && !formProps.isValidating) {
      formProps.submitForm();
    } else {
      handleMsgType({
        type: TNotification.error,
        message: `Invalid form values!\n${JSON.stringify(formProps.errors)}`,
      });
    }
  };

  const onCancel = (formProps: FormikContextType<TEditSoftSkillFormValues>) => {
    formProps.resetForm();
    setIsEditMode(false);
  };

  const onArchive = () => {
    if (!isArchived) {
      softSkillSoftDeleteMutation({
        variables: { subId: softSkill?.subId ?? '' },
      });
    } else {
      softSkillRestoreMutation({
        variables: { subId: softSkill?.subId ?? '' },
      });
    }
    setIsArchived(!isArchived);
  };

  const formContext = useFormikContext<TEditSoftSkillFormValues>();
  const { handleMsgType } = useNotification();
  const [isArchived, setIsArchived] = useState(false);

  const [softSkillSoftDeleteMutation] = useSoftSkillSoftDeleteMutation({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
        dismissable: true,
        time: 1500,
      });
    },
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        message: `Soft skill archived!`,
        dismissable: true,
        time: 1500,
      });
    },
  });

  const [softSkillRestoreMutation] = useSoftSkillRestoreMutation({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
        dismissable: true,
        time: 1500,
      });
    },
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        message: `Soft skill unarchived!`,
        dismissable: true,
        time: 1500,
      });
    },
  });

  useEffect(() => {
    setIsArchived(Boolean(softSkill?.deletedAt));
  }, [softSkill]);

  return (
    <div>
      <div className={styles.buttonContainer}>
        {!isEditMode && (
          <>
            <Button
              className={styles.button}
              variant="primary"
              size="large"
              onClick={() => setIsEditMode(!isEditMode)}
            >
              Edit
            </Button>
            <Button
              className={styles.button}
              onClick={onArchive}
              variant="secondary"
              size="large"
            >
              {isArchived ? 'Unarchive' : 'Archive'}
            </Button>
          </>
        )}
        {isEditMode && (
          <>
            <Button
              className={styles.button}
              onClick={() => onSave(formContext)}
              variant="primary"
              size="large"
            >
              Save
            </Button>
            <Button
              className={styles.button}
              onClick={() => onCancel(formContext)}
              variant="negative"
              size="large"
            >
              Cancel
            </Button>
          </>
        )}
      </div>
      <div>
        <div>
          <div className={styles.uneditableFields}>
            <FormikTextInputField
              className={styles.idField}
              id="_id"
              label="ID"
              data-role="soft-skill-id"
              data-testid="soft-skill-id"
              useFormikField={useField}
              disabled
            />
            <FormikTextInputField
              className={styles.slugField}
              id="subId"
              label="Slug"
              data-role="soft-skill-sub-id"
              data-testid="soft-skill-sub-id"
              useFormikField={useField}
              disabled
            />
            <FormikTextInputField
              className={styles.granularityField}
              id="granularity"
              label="Granularity"
              data-role="soft-skill-granularity"
              data-testid="soft-skill-granularity"
              useFormikField={useField}
              disabled
            />
            <FormikTextInputField
              className={styles.ownerField}
              id="owner.label"
              label="Ownership"
              data-role="soft-skill-owner"
              data-testid="soft-skill-owner"
              useFormikField={useField}
              disabled
            />
          </div>
          <div className={styles.editableFieldsContainer}>
            <FormikTextInputField
              id="name"
              label="Name"
              data-role="soft-skill-name-input"
              data-testid="soft-skill-name-input"
              useFormikField={useField}
              disabled={!isEditMode}
            />
            <FormikTextInputField
              id="description"
              label="Description"
              data-role="soft-skill-description-input"
              data-testid="soft-skill-description-input"
              useFormikField={useField}
              placeholder={'Missing description'}
              disabled={!isEditMode}
            />
            <FormikTextInputField
              id="taxonomyVersion"
              label="Taxonomy version"
              data-role="soft-skill-taxonomy-version-input"
              data-testid="soft-skill-taxonomy-version-input"
              useFormikField={useField}
              placeholder={'Missing version'}
              type="number"
              disabled={!isEditMode}
            />
            <FormikSelectFormField
              id="category"
              label="Category"
              options={softSkillCategoryOptions}
              data-role="category-select"
              placeholder="Category"
              maxMenuHeight={240}
              selectProps={{
                'data-testid': 'role-level-select',
              }}
              useFormikField={useField}
              hasClearIndicator={false}
              isDisabled={!isEditMode}
            />
          </div>
        </div>
        <div className={styles.softSkillDependenciesContainer}>
          <div className={styles.softSkillRelationsContainer}>
            <h2>{isBroad(softSkill) ? 'Contains' : 'Belongs to'}</h2>
            <SoftSkillRelationsForm
              isBroadSoftSkill={isBroad(softSkill)}
              isEditMode={isEditMode}
            />
          </div>
          <div className={styles.softSkillTraitsContainer}>
            <h2>Traits</h2>
            <EditSoftSkillTraits isEditMode={isEditMode} />
          </div>
        </div>
      </div>
    </div>
  );
};
