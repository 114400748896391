import Icon from 'components/atoms/Icon';
import CircularLoader from 'components/molecules/CircularLoader/CircularLoader';
import {
  FieldArray,
  FieldArrayRenderProps,
  useField,
  useFormikContext,
} from 'formik';
import { Orientation, useTraitsQuery } from 'generated/graphql';
import { KeyboardEvent } from 'react';
import { TEditSoftSkillFormValues } from 'views/StaticContent/SoftSkills/drawers/SoftSkillDrawer.helpers';
import { TRequiredSelectOption } from 'views/StaticContent/SoftSkills/SoftSkills.helpers';
import {
  Button,
  FormikSelectFormField,
  FormikTextInputField,
} from '@spotted-zebra-uk/ui-components';
import styles from './EditSoftSkillTraits.module.scss';

type TEditSoftSkillTraitsModal = {
  isEditMode: boolean;
};

type TEditSoftSkillTrait = {
  isEditMode: boolean;
  fieldArrayHelpers: FieldArrayRenderProps;
  traitSelectOptions: TRequiredSelectOption<number>[];
  idx: number;
};

const orientationOptions: TRequiredSelectOption<Orientation>[] = [
  {
    label: 'Standard',
    value: Orientation.Standard,
  },
  {
    label: 'Inverted',
    value: Orientation.Inverted,
  },
] as const;

const EditSoftSkillTrait = ({
  isEditMode,
  fieldArrayHelpers,
  traitSelectOptions,
  idx,
}: TEditSoftSkillTrait) => {
  const handleCustomWeightsKeyPress = (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    // need this to prevent keyboard typing
    if (['e', 'E', '+', '-', ','].includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div key={idx} className={styles.editSoftSkillTraitContainer}>
      <FormikSelectFormField
        className={styles.editSoftSkillTraitSelector}
        label={'Trait'}
        id={`softSkillTraits.${idx}.trait`}
        options={traitSelectOptions}
        isDisabled={!isEditMode}
        useFormikField={useField}
        placeholder="Name"
      />
      <FormikTextInputField
        className={styles.editSoftSkillTraitWeight}
        label="Weight"
        type="number"
        onKeyPress={handleCustomWeightsKeyPress}
        id={`softSkillTraits.${idx}.weight`}
        disabled={!isEditMode}
        useFormikField={useField}
      />
      <FormikSelectFormField
        className={styles.editSoftSkillTraitOrientation}
        label="Orientation"
        id={`softSkillTraits.${idx}.orientation`}
        options={orientationOptions}
        isDisabled={!isEditMode}
        useFormikField={useField}
      />
      {isEditMode && (
        <Button
          onClick={() => fieldArrayHelpers.remove(idx)}
          size="medium"
          variant="negative"
          type="button"
          leftIcon={<Icon icon="delete_outline" className={styles.icon} />}
        />
      )}
    </div>
  );
};

export const EditSoftSkillTraits = ({
  isEditMode,
}: TEditSoftSkillTraitsModal) => {
  const onAddTrait = (fieldArrayHelpers: FieldArrayRenderProps) => {
    fieldArrayHelpers.push({
      trait: undefined,
      orientation: {
        label: 'Standard',
        value: Orientation.Standard,
      },
      weight: 0,
    });
  };

  const { values } = useFormikContext<TEditSoftSkillFormValues>();

  const { data: traits, loading: traitsLoading } = useTraitsQuery({
    variables: {
      isArchived: false,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (traitsLoading) {
    return <CircularLoader />;
  }

  const traitSelectOptions = (traits?.Traits ?? [])
    .map(
      (trait): TRequiredSelectOption<number> => ({
        value: trait.id,
        label: trait.name,
      })
    )
    .sort((a, b) => (a.label < b.label ? -1 : 1));

  return (
    <div>
      <FieldArray
        name={'softSkillTraits'}
        render={fieldArrayHelpers => (
          <>
            <div className={styles.softSkillTraitsContainer}>
              {values.softSkillTraits.map((_, idx) => (
                <EditSoftSkillTrait
                  isEditMode={isEditMode}
                  fieldArrayHelpers={fieldArrayHelpers}
                  idx={idx}
                  traitSelectOptions={traitSelectOptions}
                  key={idx}
                />
              ))}
            </div>
            {isEditMode && (
              <Button
                className={styles.addTraitButton}
                onClick={() => onAddTrait(fieldArrayHelpers)}
                size="medium"
                variant="primary"
                type="button"
                leftIcon={<Icon icon="add" className={styles.icon} />}
              >
                Add trait
              </Button>
            )}
          </>
        )}
      />
    </div>
  );
};
