import { DEBOUNCE_TIME_DEFAULT } from 'constants/debounce';
import { Form, Formik, useField } from 'formik';
import { useDepartmentsSelect } from 'hooks/ats/useDepartmentsSelect';
import { debounce } from 'lodash';
import { useState } from 'react';
import * as yup from 'yup';
import {
  Button,
  FormikDatepicker,
  FormikMultiselectFormField,
  Modal,
  ModalSize,
  TMultiselectOption,
} from '@spotted-zebra-uk/ui-components';
import styles from './AtsLinks.module.scss';
import { FilteringArgs } from './UnlinkedTab/UnlinkedTab';

export function FilteringModal({
  companyId,
  isOpen,
  onClose,
  onConfirm,
  activeFilters,
}: FilteringModalProps) {
  const {
    selectOptions,
    searchValue,
    setSearchValue,
    hasMoreItems,
    loadMore,
    loading,
  } = useDepartmentsSelect(companyId);

  const [resetKey, setResetKey] = useState<string>(crypto.randomUUID());
  const [formCleared, setFormCleared] = useState(false);

  const initialValues: FilterFormValues = {
    dateFrom: activeFilters.dateFrom || '',
    dateTo: activeFilters.dateTo || '',
    departments: activeFilters.departments || undefined,
  };

  function handleConfirm(formValues: FilterFormValues) {
    onConfirm({
      dateFrom: formValues.dateFrom,
      dateTo: formValues.dateTo,
      departments: formValues.departments,
    });
  }

  function handleClearFilters() {
    setResetKey(crypto.randomUUID());
    setSearchValue('');
    setFormCleared(true);
  }

  function handleInputChangeProjects(value: string) {
    if (!value && searchValue !== '') {
      setSearchValue('');
      return;
    }

    if (value) {
      setSearchValue(value);
      return;
    }
  }

  const debouncedInputChangeProjects = debounce(
    handleInputChangeProjects,
    DEBOUNCE_TIME_DEFAULT
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Filter"
      modalSize={ModalSize.SMALL}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={filterValidationSchema}
        onSubmit={handleConfirm}
      >
        {({ dirty, resetForm }) => (
          <Form>
            <section className={styles.modalContentFiltering}>
              <div>
                <p className={styles.fieldLabel}>Date created</p>
                <div className={styles.dateFieldsWrapper}>
                  <FormikDatepicker
                    id="dateFrom"
                    name="dateFrom"
                    label="From"
                    useFormikField={useField}
                    bottomText="dd/mm/yyyy"
                  />

                  <FormikDatepicker
                    id="dateTo"
                    name="dateTo"
                    label="To"
                    useFormikField={useField}
                    bottomText="dd/mm/yyyy"
                  />
                </div>
              </div>
              {/* needs further investigation onto why this particular field doesn reset without this */}
              <div key={resetKey}>
                <p className={styles.fieldLabel}>Department</p>
                <FormikMultiselectFormField
                  id="departments"
                  name="departments"
                  useFormikField={useField}
                  loadMoreButtonText="Load more"
                  placeholder="Department"
                  options={selectOptions}
                  onInputChange={debouncedInputChangeProjects}
                  loadMore={hasMoreItems}
                  onLoadMore={loadMore}
                  isLoading={loading}
                />
              </div>
            </section>

            <section className={styles.modalFooter}>
              <div>
                <Button
                  onClick={() => {
                    handleClearFilters();
                    resetForm({
                      values: {
                        dateFrom: '',
                        dateTo: '',
                        departments: undefined,
                      },
                    });
                  }}
                  variant="secondary"
                  type="button"
                >
                  Clear all
                </Button>
              </div>
              <div className={styles.modalFooterBtns}>
                <Button onClick={onClose} variant="secondary" type="button">
                  Cancel
                </Button>
                <Button type="submit" disabled={!dirty && !formCleared}>
                  Apply
                </Button>
              </div>
            </section>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export type FilterFormValues = {
  dateFrom: string;
  dateTo: string;
  departments: TMultiselectOption[] | undefined;
};

type FilteringModalProps = {
  companyId: number;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (filterParams: FilterFormValues) => void;
  activeFilters: FilteringArgs;
};

const filterValidationSchema = yup.object().shape({
  dateFrom: yup
    .date()
    .test(
      'dateFrom-before-dateTo',
      'From date must be before To date',
      function (value) {
        const { dateTo } = this.parent;
        return !value || !dateTo || value <= dateTo;
      }
    ),
  dateTo: yup.date(),
});
