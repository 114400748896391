import { Formik } from 'formik';
import {
  SoftSkillCategory,
  SoftSkillGranularity,
  useCompaniesQuery,
  useSoftSkillCreateMutation,
} from 'generated/graphql';
import { useMemo } from 'react';
import { AddSoftSkillDrawerContents } from 'views/StaticContent/SoftSkills/drawers/AddSoftSkillDrawerContent';
import {
  addSoftSkillValidationSchema,
  getAddSoftSkillInitialValues,
  TAddSoftSkillFormValues,
} from 'views/StaticContent/SoftSkills/drawers/SoftSkillDrawer.helpers';
import {
  Drawer,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

type TAddSoftSkillDrawerArgs = {
  isOpen: boolean;
  onClose: () => void;
  setIsAddMode: (b: boolean) => void;
  setDrawerSoftSkillId: (id: string | null) => void;
};

export const AddSoftSkillDrawer = ({
  isOpen,
  onClose,
  setIsAddMode,
  setDrawerSoftSkillId,
}: TAddSoftSkillDrawerArgs) => {
  const onSubmit = async (values: TAddSoftSkillFormValues) => {
    const { data, errors } = await softSkillCreateMutation({
      variables: {
        name: values?.name as string,
        description: values.description,
        category: values?.category?.value as SoftSkillCategory,
        granularity: values?.granularity?.value as SoftSkillGranularity,
        companyId: values.owner.value === -1 ? null : values.owner.value,
        taxonomyVersion: Number(values.taxonomyVersion),
      },
    });

    if (!errors && data?.softSkill?.subId) {
      setDrawerSoftSkillId(data.softSkill.subId);
    }
  };

  const { handleMsgType } = useNotification();
  const [softSkillCreateMutation] = useSoftSkillCreateMutation({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
        time: 1500,
        dismissable: true,
      });
    },
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        message: `Soft skill created!`,
        time: 1500,
        dismissable: true,
      });
    },
  });

  const { data: companies, loading: companiesLoading } = useCompaniesQuery();

  const companySelectOptions = [
    {
      label: 'Global',
      value: -1,
    },
    ...(
      companies?.Companies?.map(({ id, name }) => ({
        label: name,
        value: id,
      })) ?? []
    ).sort((a, b) => a.label.localeCompare(b.label)),
  ];

  const initialValues: TAddSoftSkillFormValues = useMemo(() => {
    return getAddSoftSkillInitialValues();
  }, []);

  return (
    <Drawer
      open={isOpen}
      loading={companiesLoading}
      title={'Add Soft Skill'}
      onClose={() => onClose()}
    >
      {isOpen && (
        <div className="add-soft-skill__drawer">
          <Formik<TAddSoftSkillFormValues>
            initialValues={initialValues}
            validationSchema={addSoftSkillValidationSchema}
            onSubmit={onSubmit}
          >
            <AddSoftSkillDrawerContents
              companySelectOptions={companySelectOptions}
              setIsAddMode={setIsAddMode}
            />
          </Formik>
        </div>
      )}
    </Drawer>
  );
};
